.lottieplayer {
    &__player {
        &.left {
            float: left;
        }
        &.right {
            float: right;
        }
        &.center {
            margin: auto;
        }
    }
}