.component {
  &__title {
    margin-bottom: @component-title-margin;
    .component-title;
    color: @component-title-color;
    background-color: @component-title-background;
  }

  &__body {
    margin-bottom: @component-body-margin;
  }

  &__instruction {
    margin-bottom: @component-instruction-margin;
    margin-top: @component-instruction-margin;
    .instruction-text;
  }

  &__inner {
    a {
      .link-text;
    }
  }
}
