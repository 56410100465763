@collapsible-boxes-item-padding: @item-padding;
@collapsible-boxes-attribution-color: @font-color;

.collapsibleBoxes-item {
  margin-bottom: @item-margin / 2;

  &__btn {
    position: relative;
    padding: @item-padding;
    padding-left: @icon-size + (@item-padding * 2);
    text-align: left;
    background-color: transparent;
    color: @font-color;
    border-radius: @item-border-radius;
    border-top: 1px solid @rav-black;
    .collapsibleBoxes-item__icon {
      color: @font-color;
    }
    .no-touch &:not(.is-selected):hover {
      background-color: transparent;
      color: @font-color;
      .transition(background-color @duration ease-in, color @duration ease-in;);
      .collapsibleBoxes-item__icon {
        color: @font-color;
      }
    }

    .dir-rtl & {
      padding-right: @item-padding;
      padding-left: @icon-size + (@item-padding * 2);
      text-align: right;
    }
  }

  &__btn.is-visited {
    background-color: transparent;
    color: @font-color;
    .collapsibleBoxes-item__icon {
      color: @font-color;
    }
  }

  &__btn.is-selected {
    .collapsibleBoxes-item__icon {
      color: @font-color;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: @item-padding;
    color: @item-color-inverted;
    .transform(translateY(-50%));

    .dir-rtl & {
      right: inherit;
      left: @item-padding;
    }
  }

  &__content {
    padding: @item-padding;
    border-radius: @item-border-radius;
  }

  &__body a {
    .link-text;
  }

  &.has-img-zoom &__image-container {
    .img-zoom();
  }
}
