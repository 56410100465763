@disabled: #ccc;
.no-marking {
  .component-inner .component-widget.disabled.submitted.complete .icon-tick,
  .component-inner .component-widget.disabled.submitted.complete .icon-cross,
  .buttons-marking-icon {
    display: none;
  }
}

#asssessment-same-id-error {
  color: red;
  z-index: 9999;
  background: white;
  border: 1px solid red;
  padding: 15px;
  width: 100%;
  text-align: center;
  font-weight: 600;
}

// Assessment countdown styles:
.component__container {
  &.countdown-not-answered-question {
    .component__inner {
      .component__widget {
        .is-disabled.is-selected {
          background-color: @disabled !important;
          color: @disabled-inverted;
          .is-checkbox, .is-radio {
            display: none !important;
          }
        }
      }
			// SLider: 
			.slider__number, .slider__number-selection, .slider__number-model-answer {
				background-color: transparent !important;
				color: @font-color;
			}
			.rangeslider--horizontal {
				.rangeslider__fill {
					background-color: transparent !important;
				}
				.rangeslider__handle {
					display: none !important;
				}
			}
      // Matching question
      &.matching__inner {
        .dropdown__btn.is-disabled {
          color: transparent !important;
        }
      }
    }
  }
}