.drawer {
  background-color: @drawer;

  &__toolbar {
    border-bottom: 1px solid @drawer-item-hover;
    height: @navigation-height;
  }

  &__btn {
    margin: @btn-margin / 2;
    padding: @btn-padding / 2;
    background-color: @drawer-icon;
    color: @drawer-icon-inverted;
    border-radius: 50%;

    .no-touch &:not(.is-disabled):not(.is-locked):hover {
      background-color: @drawer-icon-hover;
      color: @drawer-icon-inverted-hover;
      .transition(background-color @duration ease-in, color @duration ease-in;);
    }
  }

  &__back, &__close {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    min-height: 100%;
  }
}
