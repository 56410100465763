@video-player-theme-progress-color-holder: #d8e7f6;
@video-player-theme-progress-color-fill: #3b87d4;
@video-player-theme-bg-color: #f6f7fa;

@video-player-color: var(--player-color);
@video-player-color-inverted: var(--player-color-inverted);
@video-player-white: #ffffff;
@video-player-black: #000000;
@video-player-progress-bar-color: var(--video-progress-bar-color);
@video-player-progress-bar-played-color: var(--video-player-progress-fill);
@video-player-background-color-light: var(--player-background-color);
@video-player-background-color: var(--video-background-color);
@video-player-cc-background-color: var(--video-cc-background-color);
@video-player-cc-font-color: @video-player-color-inverted;
@video-player-cc-font-family: "Open Sans", Helvetica, Arial, sans-serif;
@video-player-fullscreen-icon: var(--fullscreen-icon);
@video-player-tiny-fullscreen-icon: var(--tiny-fullscreen-icon);
@video-player-picture-in-picture-icon: var(--picture-in-picture-icon);
@video-player-closed-captions-icon: var(--closed-captions-icon);
@video-player-tiny-closed-captions-icon: var(--tiny-closed-captions-icon);
@video-player-exit-fullscreen-icon: var(--exit-fullscreen-icon);
@video-player-playbackspeed-icon: var(--playback-speed-icon);
@video-player-playbackspeed-05-icon: var(--playback-speed-05-icon);
@video-player-playbackspeed-1-icon: var(--playback-speed-15-icon);
@video-player-playbackspeed-15-icon: var(--playback-speed-15-icon);
@video-player-playbackspeed-2-icon: var(--playback-speed-2-icon);
@video-player-playbackspeed-05-big-icon: var(--playback-speed-05-big-icon);
@video-player-playbackspeed-1-big-icon: var(--playback-speed-1-big-icon);
@video-player-playbackspeed-15-big-icon: var(--playback-speed-15-big-icon);
@video-player-playbackspeed-2-big-icon: var(--playback-speed-2-big-icon);
@video-player-playbackspeed-05-mobile-icon: var(--playback-speed-05-mobile-icon);
@video-player-playbackspeed-1-mobile-icon: var(--playback-speed-1-mobile-icon);
@video-player-playbackspeed-15-mobile-icon: var(--playback-speed-15-mobile-icon);
@video-player-playbackspeed-2-mobile-icon: var(--playback-speed-2-mobile-icon);
@video-player-play-icon: var(--play-icon);
@video-player-big-play-icon: var(--big-play-icon);
@video-player-mobile-play-icon: var(--mobile-play-icon);
@video-player-tiny-play-icon: var(--tiny-play-icon);
@video-player-tiny-big-play-button-icon: var(--tiny-big-play-button-icon);
@video-player-pause-icon: var(--pause-icon);
@video-player-big-pause-icon: var(--big-pause-icon);
@video-player-mobile-pause-icon: var(--mobile-pause-icon);
@video-player-tiny-pause-icon: var(--tiny-pause-icon);
@video-player-selected-icon: var(--selected-icon);
@video-player-volume-0-icon: var(--volume-0-icon);
@video-player-volume-20-icon: var(--volume-20-icon);
@video-player-volume-80-icon: var(--volume-80-icon);
@video-player-volume-100-icon: var(--volume-100-icon);
.OnSmallScreens(@rules) {
  @media all and (max-width: @device-width-small) {
    @rules();
  }
}

.playbackRate-content(@desktop-icon, @mobile-icon) {
  display: block !important;
  .vjs-icon-placeholder:before {
    content: @desktop-icon;
    .OnSmallScreens({
      content: @mobile-icon;
    });
  }
}

.playbackRate-content-medium {
  &.playbackRate-05 {
    display: block !important;
    .vjs-icon-placeholder:before {
      content: @video-player-playbackspeed-05-mobile-icon;
    }
  }
  &.playbackRate-10 {
    display: block !important;
    .vjs-icon-placeholder:before {
      content: @video-player-playbackspeed-1-mobile-icon;
    }
  }
  &.playbackRate-15 {
    display: block !important;
    .vjs-icon-placeholder:before {
      content: @video-player-playbackspeed-15-mobile-icon;
    }
  }
  &.playbackRate-20 {
    display: block !important;
    .vjs-icon-placeholder:before {
      content: @video-player-playbackspeed-2-mobile-icon;
    }
  }
}

.setBigPlayButton(@desktop-icon) {
  .vjs-big-play-button {
    display: block;
    .vjs-icon-placeholder:before {
      content: @desktop-icon;
    }
  }
}

.setControlIcon(@icon) {
  .vjs-icon-placeholder:before {
    content: @icon;
    .dir-rtl & {
      direction: ltr;
    }
  }
}

.customPlayIcon {
  &.custom-big-play-icon {
    .vjs-icon-placeholder:before {
      content: none;
    }
  }
}

.customPauseIcon {
  &.custom-big-pause-icon {
    .vjs-icon-placeholder:before {
      content: none;
    }
  }
}
//============ video player skin styles ============
.video-player__inner.bright {
  --player-color: @video-player-white;
  --player-color-inverted: @video-player-black;
  --player-background-color: rgba(0, 0, 0, 0.2);
  --video-progress-bar-color: rgba(255, 255, 255, 0.2);
  --video-player-progress-fill: @video-player-white;
  --video-background-color: @video-player-black;
  --video-cc-background-color: rgba(255, 255, 255, 0.7);
  --fullscreen-icon: url(assets/video-player-bright-theme/fullscreen-icon.svg);
  --tiny-fullscreen-icon: url(assets/video-player-bright-theme/fullscreen_20_20.svg);
  --picture-in-picture-icon: url(assets/video-player-bright-theme/picture_in_picture.svg);
  --closed-captions-icon: url(assets/video-player-bright-theme/closed_captions.svg);
  --tiny-closed-captions-icon: url(assets/video-player-bright-theme/closed_captions_20_20.svg);
  --exit-fullscreen-icon: url(assets/video-player-bright-theme/exit_fullscreen.svg);
  --playback-speed-icon: url(assets/video-player-bright-theme/playbackspeed.svg);
  --playback-speed-05-icon: url(assets/video-player-bright-theme/playbackspeed_05.svg);
  --playback-speed-1-icon: url(assets/video-player-bright-theme/playbackspeed_1.svg);
  --playback-speed-15-icon: url(assets/video-player-bright-theme/playbackspeed_15.svg);
  --playback-speed-2-icon: url(assets/video-player-bright-theme/playbackspeed_2.svg);
  --playback-speed-05-big-icon: url(assets/video-player-bright-theme/playbackspeed_05_big.svg);
  --playback-speed-1-big-icon: url(assets/video-player-bright-theme/playbackspeed_1_big.svg);
  --playback-speed-15-big-icon: url(assets/video-player-bright-theme/playbackspeed_15_big.svg);
  --playback-speed-2-big-icon: url(assets/video-player-bright-theme/playbackspeed_2_big.svg);
  --playback-speed-05-mobile-icon: url(assets/video-player-bright-theme/playbackspeed_05_mobile.svg);
  --playback-speed-1-mobile-icon: url(assets/video-player-bright-theme/playbackspeed_1_mobile.svg);
  --playback-speed-15-mobile-icon: url(assets/video-player-bright-theme/playbackspeed_15_mobile.svg);
  --playback-speed-2-mobile-icon: url(assets/video-player-bright-theme/playbackspeed_2_mobile.svg);
  --play-icon: url(assets/video-player-bright-theme/play_36_36.svg);
  --big-play-icon: url(assets/video-player-bright-theme/play_170_170.svg);
  --mobile-play-icon: url(assets/video-player-bright-theme/play_70_70.svg);
  --tiny-play-icon: url(assets/video-player-bright-theme/play_20_20.svg);
  --tiny-big-play-button-icon: url(assets/video-player-bright-theme/play_50_50.svg);
  --pause-icon: url(assets/video-player-bright-theme/pause_36_36.svg);
  --big-pause-icon: url(assets/video-player-bright-theme/pause_170_170.svg);
  --mobile-pause-icon: url(assets/video-player-bright-theme/pause_70_70.svg);
  --tiny-pause-icon: url(assets/video-player-bright-theme/pause_20_20.svg);
  --selected-icon: url(assets/video-player-bright-theme/selected-icon.svg);
  --volume-0-icon: url(assets/video-player-bright-theme/audio_mute.svg);
  --volume-20-icon: url(assets/video-player-bright-theme/audio_sound_on_20.svg);
  --volume-80-icon: url(assets/video-player-bright-theme/audio_sound_on_80.svg);
  --volume-100-icon: url(assets/video-player-bright-theme/audio_sound_on_100.svg);
}
.video-player__inner.dark {
  --player-color: @video-player-black;
  --player-color-inverted: @video-player-white;
  --player-background-color: rgba(0, 0, 0, 0.1);
  --video-progress-bar-color: rgba(0, 0, 0, 0.2);
  --video-player-progress-fill: @video-player-black;
  --video-background-color: transparent;
  --video-cc-background-color: rgba(0, 0, 0, 0.5);
  --fullscreen-icon: url(assets/video-player-dark-theme/fullscreen-icon.svg);
  --tiny-fullscreen-icon: url(assets/video-player-dark-theme/fullscreen_20_20.svg);
  --picture-in-picture-icon: url(assets/video-player-dark-theme/picture_in_picture.svg);
  --closed-captions-icon: url(assets/video-player-dark-theme/closed_captions.svg);
  --tiny-closed-captions-icon: url(assets/video-player-dark-theme/closed_captions_20_20.svg);
  --exit-fullscreen-icon: url(assets/video-player-dark-theme/exit_fullscreen.svg);
  --playback-speed-icon: url(assets/video-player-dark-theme/playbackspeed.svg);
  --playback-speed-05-icon: url(assets/video-player-dark-theme/playbackspeed_05.svg);
  --playback-speed-1-icon: url(assets/video-player-dark-theme/playbackspeed_1.svg);
  --playback-speed-15-icon: url(assets/video-player-dark-theme/playbackspeed_15.svg);
  --playback-speed-2-icon: url(assets/video-player-dark-theme/playbackspeed_2.svg);
  --playback-speed-05-big-icon: url(assets/video-player-dark-theme/playbackspeed_05_big.svg);
  --playback-speed-1-big-icon: url(assets/video-player-dark-theme/playbackspeed_1_big.svg);
  --playback-speed-15-big-icon: url(assets/video-player-dark-theme/playbackspeed_15_big.svg);
  --playback-speed-2-big-icon: url(assets/video-player-dark-theme/playbackspeed_2_big.svg);
  --playback-speed-05-mobile-icon: url(assets/video-player-dark-theme/playbackspeed_05_mobile.svg);
  --playback-speed-1-mobile-icon: url(assets/video-player-dark-theme/playbackspeed_1_mobile.svg);
  --playback-speed-15-mobile-icon: url(assets/video-player-dark-theme/playbackspeed_15_mobile.svg);
  --playback-speed-2-mobile-icon: url(assets/video-player-dark-theme/playbackspeed_2_mobile.svg);
  --play-icon: url(assets/video-player-dark-theme/play_36_36.svg);
  --big-play-icon: url(assets/video-player-dark-theme/play_170_170.svg);
  --mobile-play-icon: url(assets/video-player-dark-theme/play_70_70.svg);
  --tiny-play-icon: url(assets/video-player-dark-theme/play_20_20.svg);
  --tiny-big-play-button-icon: url(assets/video-player-dark-theme/play_50_50.svg);
  --pause-icon: url(assets/video-player-dark-theme/pause_36_36.svg);
  --big-pause-icon: url(assets/video-player-dark-theme/pause_170_170.svg);
  --mobile-pause-icon: url(assets/video-player-dark-theme/pause_70_70.svg);
  --tiny-pause-icon: url(assets/video-player-dark-theme/pause_20_20.svg);
  --selected-icon: url(assets/video-player-dark-theme/selected-icon.svg);
  --volume-0-icon: url(assets/video-player-dark-theme/audio_mute.svg);
  --volume-20-icon: url(assets/video-player-dark-theme/audio_sound_on_20.svg);
  --volume-80-icon: url(assets/video-player-dark-theme/audio_sound_on_80.svg);
  --volume-100-icon: url(assets/video-player-dark-theme/audio_sound_on_100.svg);
}

.video-player__inner.media-progress-theme-colors {
  --video-progress-bar-color: @video-player-theme-progress-color-holder;
  --video-player-progress-fill: @video-player-theme-progress-color-fill;
}

.video-player__inner.media-bg-theme-color {
  --video-background-color: @video-player-theme-bg-color;
}