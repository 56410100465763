// Global indicator
// --------------------------------------------------
.pagelevelprogress {
  &__indicator {
    width: 4rem;
    font-size: 1rem;
    border: 0.0625rem solid @black;
    border-radius: 50px;
    overflow: hidden;
  }

  &__indicator-inner {
    position: relative;
    height: @icon-size / 4;
  }

  &__indicator-bar {
    height: inherit;
    min-width: 10%;
    background-color: @black;
  }

  &__full-witdh {
    overflow: visible;
    display: flex;
    flex-direction: column;
    .nav__inner {
      margin: 0;
    }
    .pagelevelprogress__nav-btn {
      padding-right: 0;
      width: 100%;
      height: @page-level-progress-height;
      margin: 0;
      padding: 0;
      .pagelevelprogress__indicator {
        width: 100%;
        vertical-align: top;
        top: 0;
        border-radius: 0;
        .pagelevelprogress-indicator-inner {
          border-radius: 0;
        }
        .pagelevelprogress-percentage {
          display: none;
        }
      }
    } 
  }
}
