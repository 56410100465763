.article {
  &.white-text {
    color: #fff;
    .article__title,
    .block__title, 
    .component__title,
    .article__instruction,
    .block__instruction,
    .component__instruction {
      color: #fff;
    }
    .component__body .component__body-inner a,
    .block__body .block__body-inner a,
    .article__body .article__body-inner a {
      color: white;
      &:visited {
        color: white;
      }
    }
    .component {
      &.tabs {
        .tabs__nav-item-btn:not(.is-selected), .tabs__content-item-inner {
          color: white;
        }
        .tabs__widget.default {
          .tabs__nav-item-btn {
            color: white;
          }
        }
      }
    }
  }

  &.invert-button-colors {
    .btn {
      background-color: @btn-color-inverted;
      color: @btn-color;
      &:hover {
        background-color: darken(@btn-color-inverted, 20%);
      }
    }
  }
}