.btn {
  &__action:not(.is-full-width),
  &__feedback:not(.is-full-width) {
    width: auto;
  }

  &__action:not(.is-full-width) {
    margin: 0.35rem;
    .dir-rtl & {
      margin: 0.35rem;
    }
  }

  &__feedback:not(.is-full-width) {
    margin: 0.35rem;
    .dir-rtl & {
      margin: 0.35rem;
    }
  }

  &__action.is-full-width,
  &__feedback.is-full-width {
    width: auto;
    .OnDeviceSmall({
      margin: 5px;
    });
  }
   
  &__container {
    margin-top: @btn-margin;
  }

  &-icon {
    background-color: @btn-icon-color;
    color: @btn-icon-color-inverted;

    .no-touch &:not(.is-disabled):not(.is-locked):hover {
      color: @btn-icon-color-inverted-hover;
      .transition(background-color @duration ease-in, color @duration ease-in;);
    }
  }

  &-icon-circle {
    .slh-button-primary(@btn-color, @btn-color-inverted, @btn-color-hover);
    .slh-icon-button;
  }

  &-text {
    .slh-button-primary(@btn-color, @btn-color-inverted, @btn-color-hover);
    .no-touch &:not(.is-disabled):not(.is-locked):hover {}
  }

  &__feedback, &__secondary {
    .slh-button-secondary;
  }

  &-icon.is-disabled,
  &-text.is-disabled {
    .slh-button-disabled;
  }

  &-icon.is-locked,
  &-text.is-locked {
    .slh-button-disabled;
    cursor: not-allowed;
  }

  &__marking {
    padding: @item-padding * 0.75;
    border-radius: 50%;
    left: 95%;
    .dir-rtl & {
      right: 95%;
    }
  }

  .can-show-marking &__action.is-full-width,
  .can-show-marking &__feedback.is-full-width {
    margin-right: @icon-size + (@item-margin * 2) + ((@item-padding * 0.75) * 2);

    .dir-rtl & {
      margin-right: inherit;
      margin-left: @icon-size + (@item-margin * 2) + ((@item-padding * 0.75) * 2);
    }
  }
}
