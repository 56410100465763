.has-attribution {
  position: relative;
}

.component__attribution {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: @item-padding;
  background-color: fade(@background, 50%);
  color: darken(@background-inverted, 30%);
  .transform(translate3d(0,0,0));
}
