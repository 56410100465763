.slh-notify() {
  .notify__popup {
    background-color: transparent;
  }
  .notify__title-inner {
    font-size: @slh-base-xl-fontsize;
    word-wrap: break-word;
  }
  .notify__popup-inner {
    .l-container-padding(@notify-padding-ver - 1, @notify-padding-hoz + 1);
    background-color: @notify;
    color: @notify-inverted;
    border-radius: @slh-notify-border-radius;
    width: 95%;
  }
}