.menu-folding-map {
  &__navigation-item-inner {
    position: absolute;
  }
  &__pin-image {
    position: absolute;
    z-index: 4;
    transform: translate(-70%, -70%);
    cursor: pointer;
  }
  &__heading-button {
    .heading-button {
      text-align: left;
      font-size: 1.125rem;
      line-height: 120%;
      font-weight: bold;
      padding: 14px 24px;
      font-style: normal;
      background: @folding-map-main-item-button-background;
      color: @folding-map-menu-main-item-button-text-color;
      max-width: 240px;
      box-shadow: 0 0 15px @folding-map-menu-item-box-shadow-color;
      hyphens: auto;
      .OnMediumScreens({
        font-size: 0.75rem;
        padding: 5px 10px;
      });
    }
  }
  .OnMediumScreens({
    .fullscreen {
      .menu-folding-map__heading-button {
        button {
          font-size: 1.125rem;
          background: @folding-map-main-item-button-background;
          color: @folding-map-menu-main-item-button-text-color;
          font-weight: bold;
        }
      }
    }
  });
}