//sizes
@info-graphic-notify-title-size: 2rem;

//colors
@info-graphic-mobile-fullscreen-background-color: #ffffff;

//margins
@info-graphic-item-margin-bottom: 1rem;

//Media queries for mobile view
.OnMobile(@rules) { @media screen and (max-width:@device-width-small){ @rules(); } };

.left-right-placement(@ltr-direction, @rtl-direction, @info-graphic-padding-right, @info-graphic-padding-left) {
  display: flex;
  flex-direction: @ltr-direction;
  .dir-rtl & {
    flex-direction: @rtl-direction;
  }
  .OnMobile({
    display: block;
  });
  .info-graphic__popup-content-body-graphic {
    flex: 50%;
    padding-right: @info-graphic-padding-right;
    padding-left: @info-graphic-padding-left;
    padding-bottom: 1.25rem;
    .OnMobile({
      padding-left: 0;
      padding-right: 0;
    });
  }
  .info-graphic__popup-content-body-content {
    flex: 50%;
  }
}
