// Variables:
@custom-default-padding: 20px;
@custom-cell-padding: 10px;
@custom-cell-padding-with-offset: 40px;
@main-table-background: #e9eff4;
@table-heading-background: #4d8d99;
@product-details-table-background: @main-table-background;
@product-details-icon-background: #4d8d99;

// Mixins:
.text-over-image-style(@font-color) {
  .graphic__body-inner {
    position: absolute;
    width: 55%;
    color: @font-color;
    padding: 4vw 3vw 3vw 4vw;
    z-index: 2;
    .OnDeviceMedium({
      width: 65%;
    });
    .OnDeviceSmall({
      width: 90%;
    });
    blockquote {
      p {
        font-size: @page-subtitle-size;
        font-weight: @font-weight-semibold;
      }
    }
    p {
      font-size: @page-subtitle-size;
      line-height: normal;
      .OnDeviceMedium({
        font-size: @page-subtitle-size - .6;
      });
    }
  }
  .graphic__widget {
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      .OnDeviceMedium ({
        margin: 0;
      });
    }
  }
}

// Component custom classes:
.component {
  &.highlight-background {
    .component__inner {
      background-color: @item-color;
      padding: @item-padding;
      color: @item-color-inverted;
      .component__title {
        color: @item-color-inverted;
      }
    }
  }

  &.link-as-button {
    a {
      -webkit-appearance: button;
      -moz-appearance: button;
      appearance: button;
      text-decoration: none;
      line-height: initial;
      &:visited {
        color: @btn-color-inverted;
      }
      .slh-button-primary(@btn-color, @btn-color-inverted, @btn-color-hover);
    }
    .component__inner {
      a {
        &:visited {
          color: @btn-color-inverted;
        }
      }
    }
  }
} 

// Component dependent custom classes:

// Graphic component:
.graphic {
  &.text-over-image {
    .text-over-image-style(@white);
    .graphic__body-inner {
      background-color: fade(@black, 15%);
    }
  }
  &.text-over-image-black {
    .text-over-image-style(@black);
    .graphic__body-inner {
      background-color: fade(@white, 15%);
    }
  }
  &.text-over-image-highlight-color {
    .text-over-image-style(@item-color);
    .graphic__body-inner {
      background-color: fade(darken(@black, 10%), 15%);
    }
  }
  .graphic__attribution {
    line-height: 1.5em;
  }
}

// Collapsible boxes component:
.collapsible-boxes {
  &.info-collapsible-boxes{
    .collapsibleBoxes-item__btn {
      background-color: @rav-dark-gray;
      border-top: none;
      font-size: 1.3rem;

      .no-touch &:not(.is-selected):hover {
        background-color: @rav-dark-gray;
      }
    }
    .collapsibleBoxes-item__title {
      &.is-visited, &.is-selected {
        background-color: @rav-dark-gray;
      }
    }
    .collapsibleBoxes-item__content {
      background-color: @rav-dark-gray;
    }
    .collapsibleBoxes-item__icon {
      color: @font-color;
      .icon::before {
        font-size: 1.3rem;
      }
    }
  }
}

// Slideshow component:
.slide-show {
  &.large-slide-dots {
    .slick-dots {
      button {
        width: 1.875rem;
        height: 1.875rem;
      }
    }
  }
  &.item-color-slide-dots {
    .slick-dots {
      button {
        border-color: @item-color;
      }
      li.slick-active {
        button {
          border-color: @item-color;
          background-color: @item-color;
        }
      }
    }
  }
}