.video-player {
  .video-player__inner.bright,
  .video-player__inner.dark {
    .audio-poster {
      position: relative;
      top: 3.2rem;
      min-width: unset;
      margin: auto;
    }
    .vjs-seek-to-live-control {
      display: none;
    }
    .vjs-button > .vjs-icon-placeholder:before {
      font-size: 1.3rem;
      line-height: 3.6rem;
    }
    .vjs-menu-button-popup {
      .vjs-menu {
        min-width: 7rem;
        max-width: 10rem;
        white-space: nowrap;
        .vjs-menu-content {
          bottom: 2.2rem;
          background-color: @video-player-background-color-light;
          .vjs-menu-item:hover {
            background-color: @video-player-progress-bar-color;
          }
        }
        .vjs-menu-item {
          color: @video-player-color;
          font-size: 1rem;
          text-transform: unset;
          text-align: left;
          padding-left: 0.3rem;
          &.vjs-selected {
            background-color: revert;
            .vjs-menu-item-text:after {
              content: "\00a0"@video-player-selected-icon;
            }
          }
          .vjs-menu-item-text {
            font-family: "Open Sans", Helvetica, Arial, sans-serif;
          }
        }
      }
    }

    .video-js.audio-js.vjs-audio {
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      background-color: transparent;
      .vjs-control-bar {
        .vjs-picture-in-picture-control,
        .vjs-fullscreen-control {
          display: none;
        }
        .vjs-control.vjs-progress-control {
          .vjs-progress-holder {
            position: relative;
            bottom: -0.1rem;
            margin: 0 0.5rem;
          }
        }
      }
      .vjs-big-play-button {
        display: none !important;
      }
      &.vjs-paused.vjs-has-started {
        .vjs-big-play-button {
          display: none;
          visibility: hidden;
        }
      }
      &.vjs-has-started.vjs-user-inactive.vjs-playing {
        .vjs-control-bar {
          visibility: visible;
          opacity: 1;
          transition: none;
          background-color: @video-player-background-color-light;
          .vjs-control.vjs-play-control,
          .vjs-subs-caps-button,
          .vjs-volume-panel.vjs-control,
          .vjs-volume-panel.vjs-control .vjs-mute-control,
          .vjs-control.vjs-time-control,
          .vjs-time-control.vjs-time-divider,
          .vjs-control.vjs-playback-rate {
            visibility: visible;
            opacity: 1;
            transition: none;
          }
          .vjs-control.vjs-progress-control {
            .vjs-progress-holder {
              bottom: -0.1rem;
            }
          }
        }
      }
    }

    .video-js {
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      background-color: @video-player-background-color;
      .vjs-poster {
        background-color: @video-player-background-color;
      }
      .vjs-text-track-display > div > div > div {
        color: @video-player-cc-font-color !important;
        background-color: @video-player-cc-background-color !important;
        font-family: @video-player-cc-font-family;
      }
      .vjs-text-track-display {
        bottom: 2rem;
      }
      &.vjs-has-started.vjs-user-inactive.vjs-playing {
        .vjs-control-bar {
          opacity: 1;
          visibility: visible;
          transition: none;
          background-color: transparent;
          .vjs-control,
          .vjs-time-divider {
            opacity: 0;
            visibility: visible;
            transition: visibility 1s, opacity 1s;
            pointer-events: none;
            &.vjs-progress-control {
              opacity: 1;
              .vjs-progress-holder {
                bottom: 0.625rem;
                transition: 0.5s;
              }
            }
          }
        }
      }
      &.vjs-paused {
        &.vjs-has-started {
          .setBigPlayButton(@video-player-big-pause-icon);
          .vjs-big-play-button {
            .customPauseIcon;
          }
          &.vjs-ended {
            .setBigPlayButton(@video-player-big-play-icon);
            .vjs-big-play-button {
              .customPauseIcon;
            }
          }
        }
      }
      &.vjs-fullscreen {
        .vjs-tech {
          background-color: @video-player-color-inverted;
        }
        .vjs-control-bar {
          .vjs-fullscreen-control {
            .setControlIcon(@video-player-exit-fullscreen-icon);
          }
        }
      }
      .vjs-control-bar {
        background-color: @video-player-background-color-light;
        height: 3.125rem;
        .vjs-fullscreen-control {
          .setControlIcon(@video-player-fullscreen-icon);
        }
        .vjs-picture-in-picture-control {
          display: none;
          .setControlIcon(@video-player-picture-in-picture-icon);
        }
        .vjs-subs-caps-button {
          .setControlIcon(@video-player-closed-captions-icon);
          .vjs-icon-placeholder:before {
            line-height: 3.9rem;
          }
        }
        .vjs-progress-control.vjs-control {
          width: 100%;
          position: unset;
          .vjs-progress-holder {
            background: @video-player-progress-bar-color;
            margin: 0;
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 4.2rem;
            .vjs-load-progress {
              background: @video-player-progress-bar-color;
              div {
                background: @video-player-progress-bar-color;
              }
            }
            .vjs-time-tooltip {
              padding: 0.25rem;
              font-size: 0.9rem;
              top: -2rem;
              color: @video-player-color;
              background-color: @video-player-progress-bar-color;
              font-family: "Open Sans", Helvetica, Arial, sans-serif;
            }
            .vjs-play-progress {
              background: @video-player-progress-bar-played-color;
              &:before {
                display: none;
              }
            }
            .vjs-mouse-display {
              background-color: @video-player-color;
            }
          }
        }
      }
      &:hover {
        .vjs-big-play-button {
          background-color: transparent;
        }
      }
      &.vjs-paused.vjs-has-started .vjs-big-play-button,
      .vjs-big-play-button {
        display: block;
        width: 170px;
        height: 170px;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        border: none;
        color: @video-player-color;
        background-color: transparent;
        font-size: 5rem;
        background-size: cover;
        border-radius: 0;
        .customPlayIcon;

        &.playbackRate-05 {
          .playbackRate-content(@video-player-playbackspeed-05-big-icon, @video-player-playbackspeed-05-mobile-icon);
        }
        &.playbackRate-10 {
          .playbackRate-content(@video-player-playbackspeed-1-big-icon, @video-player-playbackspeed-1-mobile-icon);
        }
        &.playbackRate-15 {
          .playbackRate-content(@video-player-playbackspeed-15-big-icon, @video-player-playbackspeed-15-mobile-icon);
        }
        &.playbackRate-20 {
          .playbackRate-content(@video-player-playbackspeed-2-big-icon, @video-player-playbackspeed-2-mobile-icon);
        }
      }
      .vjs-big-play-button {
        .setControlIcon(@video-player-big-play-icon);
        &:focus {
          background-color: transparent;
        }
      }
      .vjs-time-control {
        color: @video-player-color;
        font-size: 0.9rem;
        line-height: 3.4rem;
        display: block;
        padding: 0;
        &.vjs-duration,
        &.vjs-current-time {
          width: 2.5rem;
        }
        &.vjs-time-divider {
          min-width: 1rem;
          text-align: center;
        }
      }
      .vjs-control {
        width: 3rem;
        .vjs-audio-button {
          .vjs-icon-placeholder:before {
            font-size: 2rem;
          }
        }
        &.vjs-play-control {
          .setControlIcon(@video-player-play-icon);
        }
        &.vjs-remaining-time {
          display: none;
        }
        &.vjs-paused {
          .setControlIcon(@video-player-play-icon);
        }
        &.vjs-playing {
          .setControlIcon(@video-player-pause-icon);
        }
      }
      .vjs-volume-bar {
        margin: 1.5rem 0.45rem;
      }
      .vjs-slider-horizontal {
        .vjs-volume-level:before {
          font-size: 0.8rem;
        }
      }
      .vjs-volume-panel {
        .vjs-vol-0 {
          .setControlIcon(@video-player-volume-0-icon);
        }
        .vjs-vol-1 {
          .setControlIcon(@video-player-volume-20-icon);
        }
        .vjs-vol-2 {
          .setControlIcon(@video-player-volume-80-icon);
        }
        .vjs-vol-3 {
          .setControlIcon(@video-player-volume-100-icon);
        }
        .vjs-volume-control {
          color: @video-player-color;
          .vjs-volume-level {
            background-color: @video-player-color;
          }
        }
        .vjs-mouse-display {
          font-size: 0.9rem;
          .vjs-volume-tooltip {
            color: @video-player-color;
            background-color: @video-player-progress-bar-color;
            top: -2.5rem;
            font-family: "Open Sans", Helvetica, Arial, sans-serif;
          }
        }
      }
      .vjs-playback-rate {
        .vjs-menu {
          width: 3rem;
          min-width: 3rem;
          max-width: 4rem;
        }
        .vjs-playback-rate-value {
          display: none;
        }
        .setControlIcon(@video-player-playbackspeed-icon);
        .vjs-icon-placeholder:before {
          line-height: 3.9rem;
        }
      }

      &.vjs-layout-medium {
        .vjs-text-track-display > div > div > div {
          font-size: 1rem !important;
        }
        &.vjs-fullscreen {
          .vjs-text-track-display > div > div {
            line-height: 1rem !important;
          }
        }
        &.vjs-paused.vjs-has-started .vjs-big-play-button,
        .vjs-big-play-button {
          width: 100px;
          height: 100px;
          background-size: cover;
          border-radius: 0;
          .customPlayIcon;
        }
        .vjs-big-play-button {
          .setControlIcon(@video-player-mobile-play-icon);
          .playbackRate-content-medium;
        }
        &.vjs-paused {
          &.vjs-has-started {
            .vjs-big-play-button {
              .setControlIcon(@video-player-mobile-pause-icon);
              .playbackRate-content-medium;
              .customPauseIcon;
            }
            &.vjs-ended {
              .vjs-big-play-button {
                .setControlIcon(@video-player-mobile-play-icon);
                .customPlayIcon;
              }
            }
          }
        }
      }

      &.vjs-layout-small, &.vjs-layout-x-small, &.vjs-layout-tiny {
        .vjs-duration,
        .vjs-current-time,
        .vjs-time-divider,
        .vjs-playback-rate,
        .vjs-captions-button,
        .vjs-audio-button,
        .vjs-subtitles-button {
          display: block;
        }
        .vjs-volume-panel {
          display: none;
        }
        .vjs-text-track-display > div > div > div {
          font-size: 1rem !important;
        }
        &.vjs-fullscreen {
          .vjs-text-track-display > div > div {
            line-height: 1rem !important;
          }
        }
        &.vjs-paused.vjs-has-started .vjs-big-play-button,
        .vjs-big-play-button {
          width: 100px;
          height: 100px;
          border-radius: 0;
          background-size: cover;
        }
        .vjs-big-play-button {
          .setControlIcon(@video-player-mobile-play-icon);
          .playbackRate-content-medium;
          .customPlayIcon;
        }
        &.vjs-paused {
          &.vjs-has-started {
            .vjs-big-play-button {
              .setControlIcon(@video-player-mobile-pause-icon);
              .playbackRate-content-medium;
              .customPauseIcon;
            }
            &.vjs-ended {
              .vjs-big-play-button {
                .setControlIcon(@video-player-mobile-play-icon);
                .customPlayIcon;
              }
            }
          }
        }
      }
      
      &.vjs-layout-tiny {
        .vjs-control-bar {
          .vjs-playback-rate,
          .vjs-captions-button,
          .vjs-audio-button,
          .vjs-subtitles-button,
          .vjs-progress-control {
            display: block;
            &.vjs-control .vjs-progress-holder {
              bottom: 3rem;
            }
            .vjs-volume-panel {
              display: none;
            }
          }
          height: 2.5rem;
          .vjs-control {
            .vjs-icon-placeholder:before {
              line-height: 1rem;
              font-size: 3rem;
            }
            &.vjs-play-control {
              .setControlIcon(@video-player-tiny-play-icon);
            }
            &.vjs-paused {
              .setControlIcon(@video-player-tiny-play-icon);
            }
            &.vjs-playing {
              .setControlIcon(@video-player-tiny-pause-icon);
            }
          }
          .vjs-fullscreen-control {
            .setControlIcon(@video-player-tiny-fullscreen-icon);
          }
          .vjs-subs-caps-button {
            .setControlIcon(@video-player-tiny-closed-captions-icon);
            .vjs-icon-placeholder:before {
              line-height: 1rem;
            }
          }
        }
        .vjs-big-play-button {
          .setControlIcon(@video-player-tiny-big-play-button-icon);
        }
        &.vjs-paused {
          &.vjs-has-started {
            .vjs-big-play-button {
              display: none;
            }
          }
        }
      }
    }

    &.media-progress-on-bottom {
      .video-js {
        .vjs-control-bar {
          .vjs-progress-control.vjs-control {
            z-index: 2;
            .vjs-progress-holder {
              bottom: 0;
            }
          }
        }

        &.vjs-has-started.vjs-user-inactive.vjs-playing {
          .vjs-control-bar {
            .vjs-control.vjs-progress-control {
              z-index: 2;
              .vjs-progress-holder {
                bottom: 0;
                transition: none;
              }
            }
          }
        }
      }
    }
    &.media-progress-theme-colors {
      .vjs-control-bar {
        .vjs-progress-control.vjs-control {
          .vjs-progress-holder {          
            .vjs-time-tooltip {
              color: @video-player-progress-bar-played-color;
              background-color: @video-player-progress-bar-color;
            }
          }
        }
      }
    }
  }
}
