//Mixins:
.custom-font-size(@size) {
  font-size: @size;
  line-height: 120%;
}

.hideonmobile {
  @media all and (max-width: @device-width-small) {
    display: none;
  }
}

.hideondesktop {
  @media all and (min-width: @device-width-small) {
    display: none;
  }
}

.font-size-12 { .custom-font-size(.75rem); }
.font-size-14 { .custom-font-size(.87rem); }
.font-size-16 { .custom-font-size(1rem); }
.font-size-18 { .custom-font-size(1.125rem); }
.font-size-20 { .custom-font-size(1.25rem); }
.font-size-22 { .custom-font-size(1.375rem); }
.font-size-24 { .custom-font-size(1.5rem); }
.font-size-26 { .custom-font-size(1.625rem); }
.font-size-28 { .custom-font-size(1.75rem); }
.font-size-38 { .custom-font-size(2.375rem); }
.font-size-48 { .custom-font-size(3rem); }
