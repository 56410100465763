.loading {
  background-color: fade(@shadow, 50%);
  color: @shadow-inverted;

  &__inner {
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    text-indent: -9999rem;
    .svg-main-loader {
      display: block;
      margin: 0 auto;
      height: 5rem;
      width: 5rem;
      circle {
        fill: @loader-fill-color;
      }
      stop {
        stop-color: @loader-fill-color;
      }
    }
  }
}
