.page-title-vertical-header-position(@position) {
  .page__cover-image {
    display: flex;
    align-items: @position;
    @media all and (min-width: @device-width-small) {
      .page__header-inner {
        width: @device-width-large;
      }
    }
  }
}
.page-title-horizontal-header-position(@position, @alignment) {
  .page__cover-image {
    justify-content: @position;
  }
  .page__title, .page__subtitle, .page__body, .page__instruction {
    text-align: @alignment;
  }
}

.page {
  max-width: inherit;
  .l-container-padding(@page-padding-ver, @page-padding-hoz);

  &.has-bg-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }

  &__header.has-bg-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }

  &__header {
    &.header-top-left, &.header-top-center, &.header-top-right {
      .page-title-vertical-header-position(start);
    }
    &.header-center-left, &.header-center-center, &.header-center-right {
      .page-title-vertical-header-position(center);
    } 
    &.header-bottom-left, &.header-bottom-center, &.header-bottom-right {
      .page-title-vertical-header-position(end);
    } 
    &.header-top-left, &.header-center-left, &.header-bottom-left, &.left {
      .page-title-horizontal-header-position(start, left);
    }
    &.header-top-center, &.header-bottom-center, &.header-center-center, &.center {
      .page-title-horizontal-header-position(center, center);
    }
    &.header-top-right, &.header-center-right, &.header-bottom-right, &.right {
      .page-title-horizontal-header-position(end, right);
    }
    @media all and (max-width: @device-width-large) {
      &.header-center-left, &.header-center-right, &.header-top-left,
      &.header-top-right, &.header-bottom-left, &.header-bottom-right {
        .page__header-inner {
          margin: 0;
        } 
      }
    }
    &.header-top-left, &.header-center-left, &.header-bottom-left,
    &.header-top-center, &.header-bottom-center, &.header-center-center,
    &.header-top-right, &.header-center-right, &.header-bottom-right {
      .page__cover-image.has-bg-image {
          margin-bottom: 2rem;
      }
    }
  }
  
  &__header-inner {
    .l-container-padding(@page-header-padding-ver, @page-header-padding-hoz);
    color: @page-title-color;
    background-color: @page-title-background;
  }

  &__title {
    margin-bottom: @page-title-margin;
    .page-title;
    color: @page-title-color;
  }

  &__subtitle {
    margin-bottom: @page-subtitle-margin;
    .page-subtitle;
  }

  &__body {
    margin-bottom: @page-body-margin;
    .page-body;
  }

  &__instruction {
    margin-bottom: @page-instruction-margin;
    .page-instruction;
  }

  &__body a,
  &__instruction a {
    .link-text;
  }

  //scroll to top button
  .scrollToTopImage {
    height: 6.25rem;
    width: 6.25rem;
    background-size: cover;
    position: fixed;
    margin-left: -8.75rem;
    margin-right: -8.75rem;
    z-index: 80;
    &.defaultCircle {
      background-color: @link;
      border-radius: 60px;
      &.inTopRange {
        visibility: hidden;
      }
    }
    @media all and (max-width: (@device-width-small)) {
      display: none;
    }
    .scrollToTopImageUp {
      opacity: 0;
      transition: none;
      color: #ffffff;
      margin-top: 4px;
      &.defaultScrollTopIcon {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        opacity: 1;
        &.icon-controls-up::before {
          font-size: 5rem;
        }
      }
    }
  }
  .scrollToTopImage:hover {
    cursor: pointer;
    .scrollToTopImageUp {
      opacity: 1;
    }
    &.inTopRange {
      .scrollToTopImageUp {
        opacity: 0;
      }
    }
  }
  @media all and (max-width: (72rem)) {
    .scrollToTopImage {
      margin: 0;
      bottom: 1rem;
      right: 1rem;
      height: 3.75rem;
      width: 3.75rem;
      opacity: .6;
      .dir-rtl & {
        right: unset;
        left: 1rem;
      }
      &.inTopRange {
        visibility: hidden;
      }
      .scrollToTopImageUp {
        font-size: 24px;
        opacity: 1;
        &.defaultScrollTopIcon {
          &.icon-controls-up::before {
            font-size: 3rem;
          }
        }
      }
    }
  }
}
