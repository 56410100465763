.graphic {

  &.is-scrollable .graphic__image-container {
    display: block;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  &.is-scrollable .graphic__image-container img {
    max-width: initial;
  }

}
