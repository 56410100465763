@trickle-disabled-color: @disabled;

.trickle {
  &__btn-icon {
    display: inline-block;
    vertical-align: middle;
  }

  &__btn-text {
    display: inline-block;
    vertical-align: middle;
  }
}
