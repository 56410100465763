.transition(@args) {
  transition: @args;
}
.pagelevelprogressdots {
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 0;
  position: fixed;
  height: 65%;
  overflow: hidden;
  z-index: 89;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  .pagelevelprogress__inner {
    max-height: 50%;
  }
  &__inner {
    .js-children {
      margin-right: 0.625rem;
    }
  }
  &__item {
    position: relative;
    &.drawer__item {
      background-color: transparent;
    }
    .is-incomplete:before {
      font-size: 1.5rem;
    }
    .no-touch {
      .drawer__item-btn:not(.is-disabled):not(.is-locked) {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  &__item-btn {
    &.drawer__item-btn {
      border-bottom: none;
      background-color: transparent;
      &.is-disabled {
        background-color: transparent;
      }
      .pagelevelprogress__indicator:before {
        font-size: 1.5rem;
      }
      .no-touch .drawer__item-btn:not(.is-disabled):not(.is-locked):hover {
        background-color: transparent;
        transition: background-color 0.2s ease-in, color 0.2s ease-in;
      }
    }
    &.arrow-pointer {
      &:before { 
        margin-top: 0.188rem;
        content: "\e982"; 
        position: absolute;
        font-size: 0.938rem;
        top: 0.813rem;
        right: 0.125rem;
      }
    }
    &.optional-dot {
      width: 100%;
      .pagelevelprogress__indicator {
        display: none;
      }
    }
  }
  .pagelevelprogress__indicator {
    width: 100%;
    padding: 0.625rem 0.938rem 0.625rem 0;
  }
}

.no-touch {
  .pagelevelprogressdots__item-btn {
    &.drawer__item-btn {
      display: flex;
      flex-direction: row-reverse;
      border-bottom: none;
      background-color: transparent;
      padding: 0;
      &.optional-dot {
        .pagelevelprogressdots__item-optional {
          width: 100%;
          padding: 0.625rem 0.938rem 0.625rem 0.938rem;
          margin: 0.313rem 1.25rem 0.313rem 0;
          border-radius: @page-level-progress-dots-border-radius;
        }
      }
      &.is-disabled {
        background-color: transparent;
      }
      &:not(.is-disabled):not(.is-locked):hover {
        background-color: transparent;
        color: @page-level-progress-dots-hover;
        transition: color 0.2s ease-in;
        &::before {
          color: @page-level-progress-dots-hover;
          .transition(background-color @duration ease-in, color @duration ease-in;);
        }
        .pagelevelprogress__indicator {
          &::before {
            color: @page-level-progress-dots-hover;
            .transition(background-color @duration ease-in, color @duration ease-in;);
          }
        }
      }
    }
    &.drawer__item-btn.optional-dot {
      &:not(.is-disabled):not(.is-locked):hover {
        .pagelevelprogressdots__item-optional {
          background-color: @page-level-progress-dots-hover;
          color: @page-level-progress-dots-text-hover;
          .transition(background-color @duration ease-in, color @duration ease-in;);
        }
      }
    }
  }
}

