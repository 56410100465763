.menu-folding-map {
    &__header-inner {
      padding: @folding-map-menu-header-padding-ver @folding-map-menu-header-padding-hoz;
      margin: auto;
    &.large {
      max-width: 850px;
    }
    &.medium {
      max-width: 600px;
    }
    &.small {
      max-width: 400px;
    }

    .menu-folding-map__subtitle-inner {
      .menu-subtitle();
    }
  }
  &__title {
    padding-bottom: 1.25rem;
  }
  &__body-inner {
    .menu-body();
    p {
      margin: 0;
    }
  }
  &__title-inner {
    padding: 1rem 0 1rem;
    display: block;
    .menu-title();
    font-size: 2rem;
  }
  &__container {
    margin-left: auto;
    margin-right: auto;
    .responsive-large;
    .responsive-medium;
    .responsive-small;
    padding-bottom: 800px;
    .OnSmallScreens ({
      width: 330px;
      padding-bottom: 500px;
    });

    &.large {
      .menu-items-width(1100px, 850px);
    }
    &.medium {
      .menu-items-width(800px, 600px);
    }
    &.small {
      .menu-items-width(600px, 400px);
    }
  }
  &.top {
    background-position: top left;
    background-size: 100%;
  }
  &.cover {
    background-size: cover;
    position: relative;
  }
  &.left {
    .horizontal-position(left);
  }
  &.right {
    .horizontal-position(right);
  }
}