@video-player-cc-font-family: @body-family;
@video-player-theme-progress-color-holder: @progress-inverted;
@video-player-theme-progress-color-fill: @progress;

.media {
  &__transcript-container {
    margin-top: @item-margin;
  }

  &__transcript-btn {
    padding: @item-padding;
    background-color: @item-color;
    color: @item-color-inverted;
    border-radius: @item-border-radius;

    .no-touch &:hover {
      background-color: @item-color-hover;
      color: @item-color-inverted-hover;
      .transition(background-color @duration ease-in, color @duration ease-in;);
    }
  }

  &__transcript-body-inline {
    margin-top: @item-margin;
    padding: @item-padding;
  }

  &__transcript-body-inline a {
    .link-text;
  }
}
