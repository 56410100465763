// To allow children elements to be positioned absolutely
// --------------------------------------------------
.component { position: relative; }
.block { position: relative; }

// jquery.a11y start
// Invisible aria-labels
// --------------------------------------------------
.aria-label {
  position: absolute !important;
  &.relative {
    position: relative !important;
  }
  left: 0 !important;
  width: auto !important;
  height: auto !important;
  overflow: auto !important;
  color: rgba(0,0,0,0) !important;
  background: rgba(0,0,0,0) !important;
  font-size: 1px !important;
  padding: 0 !important;
  margin: 0 !important;
  line-height: normal !important;
  z-index: 1;
}

// Force aria-labels to hide on hidden
// --------------------------------------------------
.aria-label.aria-hidden {
  display: none !important;
}

// Hidden focus guard
// --------------------------------------------------
.a11y-focusguard {
  left: 0 !important;
  bottom: 0 !important;
  width: auto !important;
  height: auto !important;
  overflow: auto !important;
  color: rgba(0,0,0,0) !important;
  background: rgba(0,0,0,0) !important;
  font-size: 1px !important;
  padding: 0 !important;
  margin: 0 !important;
  line-height: normal !important;
  z-index: 1;

  // iPad: Stop accessibility cursor focusing until end of page;
  // --------------------------------------------------
  .touchevents & {
    position: absolute !important;
  }

  // Desktop: stop focus making window jump to bottom of page;
  // --------------------------------------------------
  .no-touchevents & {
    position: fixed !important;
  }
}

// Focus distraction
// --------------------------------------------------
#a11y-focuser {
  bottom: 0 !important;
  position: fixed !important;
  left: 0 !important;
  width: auto !important;
  height: auto !important;
  overflow: auto !important;
  color: rgba(0,0,0,0) !important;
  background: rgba(0,0,0,0) !important;
  font-size: 1px !important;
  padding: 0 !important;
  margin: 0 !important;
  line-height: normal !important;
  z-index: 1;
}

.a11y-disable-focusoutline *:focus {
  outline: none !important;
}
