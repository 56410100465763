.notify.info-graphic {
  .info-graphic__popup-toolbar {
      display: flex;
    &.button-left {
      justify-content: flex-start;
    }
    &.button-center {
      justify-content: center;
    }
    &.button-right {
      justify-content: flex-end;
    }
  }

  .info-graphic__popup-item-content-title {
    margin-bottom: @info-graphic-item-margin-bottom;
    font-size: @info-graphic-notify-title-size;
  }

  .info-graphic__popup-content-body {
    .info-graphic__popup-content-body-graphic {
      padding-bottom: 1rem;
    }
    .info-graphic__popup-content-body-graphic-image {
      margin-left: auto;
      margin-right: auto;
      min-width: unset;
    }
    &.graphic-left {
      .left-right-placement(row, row-reverse, 1rem, 0);
    }
    &.graphic-right {
      .left-right-placement(row-reverse, row, 0, 1rem);
    }
    .info-graphic__popup-content-body-content {
      p {
        margin-top: 0;
      }
    }
  }
}