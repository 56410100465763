.collapsibleBoxes {
  overflow-anchor: none;

  &__widget {
    .onDesktop({
      &.collapsibleBoxes__row-items-2, &.collapsibleBoxes__row-items-3, &.collapsibleBoxes__row-items-4 {
        display: flex;
        flex-wrap: wrap;
      }
      &.collapsibleBoxes__row-items-2 {
        .row-items(50%);
      }
      &.collapsibleBoxes__row-items-3 {
        .row-items(33%);
      }
      &.collapsibleBoxes__row-items-4 {
        .row-items(25%);
      }
    })
  }
}

.collapsibleBoxes-item {
  &__btn {
    width: 100%;
  }

  &__content {
    display: none;
  }

  &__content-inner {
    &.left-layout {
      display: flex;
      .OnSmallScreens({
        display: inline-grid;
      });
      flex-direction: row;
      .dir-rtl & {
        flex-direction: row-reverse;
      }
      .allImageSizes;
    }
    &.right-layout {
      display: flex;
      .OnSmallScreens({
        display: inline-grid;
      });
      flex-direction: row-reverse;
      .dir-rtl & {
        flex-direction: row;
      }
      .allImageSizes;
    }
    &.top-layout {
      display: flex;
      .OnSmallScreens({
        display: block;
      });
      flex-direction: column;
    }
    &.bottom-layout {
      display: flex;
      .OnSmallScreens({
        display: block;
      });
      flex-direction: column-reverse;
    }
  }

  &__image {
    min-width: initial;
    margin: auto;
  }

  // Item icon states
  // --------------------------------------------------
  &__btn.is-closed .icon {
    .icon-plus;
  }

  &__btn.is-open .icon {
    .icon-minus;
  }
}
