.mcq-item {
  &__label {
    margin-bottom: @item-margin / 2;
    background-color: transparent;
    color: @font-color;
    border-radius: @item-border-radius;

    .no-touch &:not(.is-disabled):not(.is-selected):hover,
    html:not(.a11y-disable-focusoutline) .mcq-item__input:focus + & {
      background-color: transparent;
      color: @font-color;
      .transition(background-color @duration ease-in, color @duration ease-in;);
    }

    .no-touch &:not(.is-disabled):not(.is-selected):hover .mcq-item__icon {
      .effect-scalePulse;
    }
  }

  &__label.is-disabled {
    background-color: @disabled;
    color: @disabled-inverted;
  }

  &__label.is-selected {
    background-color: transparent;
    color: @font-color;
  }

  &__state {
    position: absolute;
    top: 50%;
    left: @item-padding;
    .transform(translateY(-50%));

    .dir-rtl & {
      left: inherit;
      right: @item-padding;
    }
  }

  &__text {
    padding: @item-padding @item-padding @item-padding (@icon-size + (@item-padding * 2));

    .dir-rtl & {
      padding-left: @item-padding;
      padding-right: @icon-size + (@item-padding * 2);
    }
  }
  &.is-correct {
    .mcq-item__label.is-selected {
      background-color: fade(@validation-success, 60%);
      color: @font-color;
    }
  }
  &.is-incorrect {
    .mcq-item__label.is-selected {
      background-color: fade(@validation-error, 60%);
      color: @font-color;
    }
  }
  &__text-inner {
    hyphens: auto;
  }
}
