// Assets:
@folding-map-menu-item-status-complete-image: url(assets/check.svg);
@folding-map-menu-item-status-locked-image: url(assets/lock.svg);

//colors
@folding-map-menu-circle-color: #3b87d4;
@folding-map-menu-circle-color-inverted: #FFFFFF;
@folding-map-menu-background-color: #FFFFFF;
@folding-map-menu-locked-icon-color: #39455D;
@folding-map-menu-complete-icon-color: #39455D;
@folding-map-main-item-button-background: #DAECFF;
@folding-map-menu-main-item-button-text-color:#39455D;
@folding-map-menu-item-box-shadow-color: rgba(153,153,153,1);

//fonts
@folding-map-menu-item-title-size: 1.25rem;
@folding-map-menu-body-size: 1.2rem;

//paddings
@folding-map-menu-header-padding-hoz: .5rem;
@folding-map-menu-header-padding-ver: 2rem;

// =================
// Responsive Widths
// =================
.responsive-large {
  @media all and (min-width:@device-width-large) and (min-width:(@device-width-medium + 1)) {
    width:@device-width-large;
    margin:0 auto;
  }
}

.responsive-medium {
  @media all and (max-width:@device-width-medium) and (min-width:(@device-width-small + 1)) {
    width:@device-width-medium;
    margin:0 auto;
  }
}

.responsive-small {
  @media all and (max-width:(@device-width-small)) {
    width:100%;
    margin:0 auto;
  }
}

.responsive-layout {
  .responsive-large;
  .responsive-medium;
  .responsive-small;
}

//mixins
.OnSmallScreens(@rules) { @media all and (max-width: @device-width-small){ @rules(); }};
.OnMediumScreens(@rules) { @media all and (max-width: @device-width-medium){ @rules(); }};

.item-circle(@background, @text-color) {
  width: 70px;
  height: 70px;
  border-radius: 60px;
  background: @background;
  color: @text-color;
  .OnSmallScreens ({
    width: 70px;
    height: 70px;
  });
}

.menu-items-width(@container-width, @items-width) {
  max-width: @container-width;
  .menu-folding-map__item-inner {
    max-width: @items-width;
    margin: auto;
  }
}

.horizontal-position(@pos) {
  display: grid;
  justify-content: @pos;
  .OnSmallScreens ({
    justify-content: center;
  });
}