.dropdown {
  
  &__inner {
    text-overflow: unset;
    overflow: unset;
    white-space: unset;
  }

  &__btn {
    position: relative;
    padding: @item-padding (@icon-size + (@item-padding * 2)) @item-padding @item-padding;
    line-height: @body-line-height;
    text-align: left;
    background-color: @item-color;
    color: @item-color-inverted;

    .no-touch &:not(.is-disabled):not([aria-expanded="true"]):hover,
    html:not(.a11y-disable-focusoutline) &:focus {
      background-color: @item-color-hover;
      color: @item-color-inverted-hover;
      .transition(background-color @duration ease-in, color @duration ease-in;);
    }

    .dir-rtl & {
      padding-right: @item-padding;
      padding-left: @icon-size + (@item-padding * 2);
      text-align: right;
    }
  }

  &__btn.is-disabled {
    background-color: @disabled;
    color: @disabled-inverted;
  }

  &__btn[aria-expanded="true"] {
    background-color: @item-color-selected;
    color: @item-color-inverted-selected;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: @item-padding;
    .transform(translateY(-50%));

    .dir-rtl & {
      right: inherit;
      left: @item-padding;
    }
  }
}

.dropdown-item {
  padding: @item-padding;
  background-color: @item-color;
  border-bottom: 1px solid @item-color-inverted;
  color: @item-color-inverted;

  .no-touch &:not(:focus):hover {
    background-color: @item-color-hover;
    color: @item-color-inverted-hover;
    .transition(background-color @duration ease-in, color @duration ease-in;);
  }

  &:focus,
  &[aria-selected="true"] {
    // This code can be used to style a non-native dropdown as closely
    // as possible to the default browser settings
    // background-color: Highlight;
    // background-color: -webkit-focus-ring-color;
    // color: white;

    background-color: @item-color;
    color: @item-color-inverted;
  }
}
