// Add top padding equal to the height of the navigation bar
// --------------------------------------------------
#wrapper {
  padding-top: @navigation-height;
}

.nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 80;
  background-color: red;

  &__inner {
    .l-container-responsive(@max-width);
  }

  &__skip-btn {
    position: absolute;
    left: 0;
    margin-top: 0;
    top: -100%;
    z-index: 100;
    .transition(top @duration ease-in-out);

    html:not(.has-accessibility) & {
      .u-display-none;
    }

    &:focus {
      top: 0;
    }
  }

  &__back-btn {
    .u-float-left;
  }

  &__back-btn .icon {
    .icon-controls-small-left;
  }

  &__drawer-btn {
    .u-float-right;
  }

  &__drawer-btn .icon {
    .icon-list;
  }
}
