.drawer {
  position: fixed;
  top: 0;
  right: -@drawer-width;
  // Update JS to set start point
  // Update animation to be css rather than js
  height: 100%;
  width: @drawer-width;
  z-index: 100;

  .dir-rtl & {
    right: inherit;
    left: -@drawer-width;
  }

  &__inner {
    position: relative;
    height: 100%;
    padding-top: @navigation-height;
  }

  // Add top padding equal to the height of the navigation bar
  // --------------------------------------------------
  &__holder {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  // Reposition the toolbar to visually sit at the top of the drawer
  // --------------------------------------------------
  &__toolbar {
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__back {
    .u-float-left;
  }

  &__back .icon {
    .icon-controls-small-left;
  }

  &__close {
    .u-float-right;
  }

  &__close .icon {
    .icon-cross;
  }
}
