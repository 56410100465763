.glossary {
  &__textbox-container {
    margin: (@item-margin * 2) (@item-margin * 2) 0;
  }

  &__textbox {
    line-height: @drawer-item-body-line-height;
  }

  &__checkbox-container {
    padding: @item-padding;
  }

  &__checkbox-input,
  &__checkbox-label {
    vertical-align: middle;
  }

  &__checkbox-label {
    margin-left: @item-margin;
    color: @drawer-item-inverted;
    font-size: 1rem;

    .dir-rtl & {
      margin-left: inherit;
      margin-right: @item-margin;
    }
  }

  &__search-container,
  &__index-container {
    background-color: @drawer;
    border-bottom: 1px solid @drawer-item-hover;
  }

  &__index-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
  }

  &__index-link {
    .link-text;
    color: @drawer-link;

    .no-touch &:hover {
      color: @drawer-link-hover;
      .transition(color @duration ease-in);
    }
  }

  &__items-group-header {
    background-color: @drawer-item-hover;
    color: @drawer-item-inverted-hover;
    text-align: center;
  }
}
