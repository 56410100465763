.mcq {
  // Class to show the selection icons until the component is complete
  // --------------------------------------------------
  &__widget:not(.is-complete) &-item__answer-icon {
    display: block;
  }

  // Class to show the users selection
  // --------------------------------------------------
  &__widget.show-user-answer .is-correct .is-selected &-item__correct-icon,
  &__widget.show-user-answer .is-incorrect .is-selected &-item__incorrect-icon {
    display: block;
  }

  &__widget.show-user-answer &-item:not(.is-correct):not(.is-incorrect) .is-selected &-item__answer-icon {
    display: block;
  }

  // Class to show the correct answers
  // --------------------------------------------------
  &__widget.show-correct-answer .is-correct .is-selected &-item__correct-icon,
  &__widget.show-correct-answer .is-incorrect .is-selected &-item__correct-icon {
    display: block;
  }

  &__widget.show-correct-answer &-item:not(.is-correct):not(.is-incorrect) .is-selected &-item__answer-icon {
    display: block;
  }
}

.mcq-item {
  position: relative;

  &__input {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    z-index: -1;
    .transform(translate(-50%, -50%));
  }

  &__label {
    display: block;
    z-index: 1;
  }

  &__label:not(.is-disabled) {
    cursor: pointer;
  }

  &__icon {
    display: none;
  }

  &__text {
    display: block;
  }

  // MCQ item icons
  // --------------------------------------------------
  &__answer-icon.is-radio .icon {
    .icon-radio-unchecked;
  }

  &__answer-icon.is-checkbox .icon {
    .icon-checkbox-unchecked;
  }

  &__label.is-selected &__answer-icon.is-radio .icon {
    .icon-radio-checked;
  }

  &__label.is-selected &__answer-icon.is-checkbox .icon {
    .icon-checkbox-checked;
  }

  &__correct-icon .icon {
    .icon-tick;
  }

  &__incorrect-icon .icon {
    .icon-cross;
  }
}
