// Custom SLH buttons:
@slh-button-padding: 0.75rem 1.5rem;
@slh-button-border-radius: 0rem;
@slh-border-margin: 0.313rem;

.slh-button() {
  padding: @slh-button-padding;
  border-radius: @slh-button-border-radius;
  text-align: center;
  hyphens: auto;
  margin: 0.35rem;
  font-weight: @btn-weight;
  .dir-rtl & {
    margin: 0.35rem;
  }
  .OnDeviceSmall({
    margin: @slh-border-margin;
  });
}

.slh-button-primary(@bck-color, @color, @hover-bck-color) {
  .slh-button;
  background-color: @bck-color;
  color: @color;
  &:hover {
    background-color: @hover-bck-color;
  }
}

.slh-button-secondary {
  .slh-button;
  background-color: @secondary-btn-color;
  color: @secondary-btn-color-inverted;
  &:hover {
    background-color: @secondary-btn-color-hover;
    color: @secondary-btn-color-inverted-hover;
  }
}

.slh-button-tertiary(transparent, @slh-color-neutral-shade-80, transparent) {
  .slh-button;
  background-color: fade(@bck-color, 10%);
  color: @color;
  &:hover {
    background-color: fade(@bck-color, 20%);
  }
}

.slh-button-disabled {
  .slh-button;
  background-color: @disabled;
  color: @disabled-inverted;
  &:hover {
    background-color: @disabled;
  }
}

.slh-icon-button {
  width: 48px;
  height: 48px;
  display: inline;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 40px;
}

// TO BE DEFINED LATER IF IT'S USED
// .slh-button-ghost() {
//   .slh-button-mixin(@slh-color-neutral-shade-00, @slh-color-primary, @slh-color-neutral-shade-00);
//   border: 2px solid @slh-color-primary-shade-60;
//   &:hover {
//     border-color: @slh-color-primary;
//   }
// }

// .slh-button-negative() {
//   .slh-button-mixin(@slh-color-error-shade-20, @slh-color-error, @slh-color-error-shade-40);
// }