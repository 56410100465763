.drawer {
  &__item-btn {
    width: 100%;
  }
  
  &__item-title,
  &__item-body {
    display: block;
  }
}
