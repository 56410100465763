.article {
  &.has-bg-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }

  &__header-inner {
    .l-container-padding(@article-header-padding-ver, @article-header-padding-hoz);
  }

  &__title {
    margin-bottom: @article-title-margin;
    .article-title;
    color: @article-title-color;
    background-color: @article-title-background;
  }

  &__body {
    margin-bottom: @article-body-margin;
  }

  &__instruction {
    margin-bottom: @article-instruction-margin;
    .instruction-text;
  }

  &__body a,
  &__instruction a {
    .link-text;
  }
}
