.resources {
  &__filter-inner {
    display: flex;
  }

  &__filter-btn {
    flex-grow: 1;
  }

  html:not(.ie) &__filter-btn {
    flex-basis: 0;
  }
}
