@nav-progress-height: 4px;
@nav-progress-border-radius: 50px;
@course-progress-border-radius: 50px;
@progress-height: @nav-progress-height;

#wrapper, #adapt {
  background-color: @background;
  -webkit-tap-highlight-color: transparent;
}

.inline-icon {
  display: inline-block;
  vertical-align: middle;
}

table, tr, td {
  border: none;
  tbody, thead {
    vertical-align: top;
  }
}