// Variables
@custom-block-border-gray: @slh-color-neutral-shade-40;
@custom-block-pop-out-background: @slh-color-neutral-shade-20;
@custom-block-pop-out-title-background: @slh-color-neutral-shade-40;
@custom-title-text-margin: 1.5rem;

// mixins
.block-inner-elements-padding (@padding) {
  .block__title, .block__body, .block__instruction, .component__container {
    padding-left: @padding;
    padding-right: @padding;
  }
}

.component-container(@border-color, @bck-color) {
  .block__inner {
    .component__container {
      border: 5px solid @border-color;
      border-radius: 20px;
      background-color: @bck-color;
      padding: @item-padding;
    }
  }
}

.questions-on-bg-color {
  .matching-item__title,
  .mcq-item__label, 
  .mcq-item__label:hover, 
  .mcq-item__label.is-disabled,
  .mcq-item.is-correct .mcq-item__label.is-selected,
  .mcq-item.is-incorrect .mcq-item__label.is-selected,
  .mcq-item__label:not(.is-disabled):not(.is-selected):hover,
  .gmcq-item__option, 
  .gmcq-item__label:hover, 
  .gmcq-item.is-correct .gmcq-item__label.is-selected .gmcq-item__option,
  .gmcq-item.is-incorrect .gmcq-item__label.is-selected .gmcq-item__option,
  .gmcq-item__label:not(.is-disabled):not(.is-selected):hover,
  .gmcq-item__label:not(.is-disabled):not(.is-selected):hover .gmcq-item__option,
  .gmcq-item__label.is-selected .gmcq-item__option,
  .gmcq-item__label.is-disabled .gmcq-item__option {
    background-color: transparent;
    color: #000;
  }
  .dropdown__btn, .dropdown-item {
    background-color: #ccc;
    color: #000;
    &:hover, &:not(:focus):hover {
      background-color: darken(#ccc, 10%);
    }
  }
  .matching-item.is-correct .dropdown .dropdown__btn.is-disabled {
    background-color: @validation-success;
  }
  .matching-item.is-incorrect .dropdown .dropdown__btn.is-disabled {
    background-color: @validation-error;
  }
  .btn-text, .dropdown__btn {
    &:not(.is-disabled),  &:not(.is-disabled):not([aria-expanded="true"]) {
      background-color: #ccc;
      color: #000;
      &:hover {
        background-color: darken(#ccc, 10%);
      }
    }
  }
}

.block-inner(@border-color, @bck-color) {
  .block__inner {
    border: 5px solid @border-color;
    border-radius: 20px;
    background-color: @bck-color;
    .block-inner-elements-padding(@item-padding);
  }
}

html:not(.a11y-disable-focusoutline) {
  .block-bg-coral,
  .block-bg-rosa,
  .block-bg-purple,
  .block-bg-violet,
  .block-bg-blue,
  .block-bg-blue1,
  .block-bg-blue2,
  .block-bg-mint,
  .block-bg-mint2,
  .block-bg-green,
  .block-bg-green2,
  .block-bg-green3,
  .block-bg-yellow,
  .block-bg-orange,
  .block-bg-orange2,
  .block-bg-red1,
  .block-bg-brown,
  .block-bg-gray1,
  .block-bg-gray2,
  .block-bg-violet2,
  .block-bg-black2,
  .block-bg-petrol,
  .block-bg-curry,
  .block-bg-peru,
  .block-bg-sky
  .block-bg-lightpink,
  .block-bg-midnightblue,
  .block-bg-lightkaki,
  .block-bg-lightseegreen,
  .block-bg-salmon {
    .mcq-item__input:focus+.mcq-item__label {
      background-color: transparent;
      color: #000;
      transition: none;
    }
    &.block-fontColor-inverted {
      .mcq-item__input:focus+.mcq-item__label {
        background-color: transparent;
        color: #fff;
        transition: none;
      }
    }
  }
}

// classes
.block {

  &.block-content-border-highlight {
    .component-container(@item-color, @white);
  }
  
  &.block-content-border-gray {
    .component-container(@custom-block-border-gray, @white);
  }
  
  &.block-border-highlight {
    .block-inner(@item-color, @white);
  }
  
  &.block-border-gray {
    .block-inner(@custom-block-border-gray, @white);
  }
  
  &.block-title-pop-out {
    padding-top: 40px;
    .block__inner {
      background-color: @custom-block-pop-out-background;
      position: relative;
      .block__title {
        color: @black;
        padding: @item-padding;
        background: @custom-block-pop-out-title-background;
        width: 80%;
        position: absolute;
        margin: (-@custom-title-text-margin - 2) 0 0 @custom-title-text-margin;
      }
      .block-inner-elements-padding(@item-padding);
      .block__body {
        padding-top: 45px;
      }
      .component__container {
        padding-top: 25px;
      }
    }
  }

  &.white-text {
    color: #fff;
    .block__title, 
    .component__title,
    .block__instruction,
    .component__instruction {
      color: #fff;
    }
    .component__body .component__body-inner a,
    .block__body .block__body-inner a {
      color: white;
      &:visited {
        color: white;
      }
    }
    .component {
      &.tabs {
        .tabs__nav-item-btn:not(.is-selected), .tabs__content-item-inner {
          color: white;
        }
        .tabs__widget.default {
          .tabs__nav-item-btn {
            color: white;
          }
        }
      }
    }
  }

  &.invert-button-colors {
    .btn {
      background-color: @btn-color-inverted;
      color: @btn-color;
      &:hover {
        background-color: darken(@btn-color-inverted, 20%);
      }
    }
  }

  &.block-bg-coral{
    background-color: @coral;
    .questions-on-bg-color;
  }
  
  &.block-bg-rosa{
    background-color: @rosa;
    .questions-on-bg-color;
  }
  
  &.block-bg-purple{
    background-color: @purple;
    .questions-on-bg-color;
  }
  
  &.block-bg-violet{
    background-color: @violet;
    .questions-on-bg-color;
  }
  
  &.block-bg-blue{
    background-color: @blue;
    .questions-on-bg-color;
  }
  
  &.block-bg-blue1{
    background-color: @blue1;
    .questions-on-bg-color;
  }
  
  &.block-bg-blue2{
    background-color: @blue2;
    .questions-on-bg-color;
  }
  
  &.block-bg-mint{
    background-color: @mint;
    .questions-on-bg-color;
  }
  
  &.block-bg-mint2{
    background-color: @mint2;
    .questions-on-bg-color;
  }
  
  &.block-bg-green{
    background-color: @green;
    .questions-on-bg-color;
  }
  
  &.block-bg-green2{
    background-color: @green2;
    .questions-on-bg-color;
  }
  
  &.block-bg-green3{
    background-color: @green3;
    .questions-on-bg-color;
  }
  
  &.block-bg-yellow{
    background-color: @yellow;
    .questions-on-bg-color;
  }
  
  &.block-bg-orange{
    background-color: @orange;
    .questions-on-bg-color;
  }
  
  &.block-bg-orange2{
    background-color: @orange2;
    .questions-on-bg-color;
  }
  
  &.block-bg-red1{
    background-color: @red1;
    .questions-on-bg-color;
  }
  
  &.block-bg-brown{
    background-color: @brown;
    .questions-on-bg-color;
  }
  
  &.block-bg-gray1{
    background-color: @gray1;
    .questions-on-bg-color;
  }
  
  &.block-bg-gray2{
    background-color: @gray2;
    .questions-on-bg-color;
  }
  
  &.block-bg-violet2{
    background-color: @violet2;
    .questions-on-bg-color;
  }
  
  &.block-bg-black2{
    background-color: @black2;
    .questions-on-bg-color;
  }
  
  &.block-bg-petrol {
      background-color: @petrol;
      .questions-on-bg-color;
  }
  
  &.block-bg-curry {
      background-color: @curry;
      .questions-on-bg-color;
  }
  
  &.block-bg-peru {
      background-color: @peru;
      .questions-on-bg-color;
  }
  
  &.block-bg-sky {
      background-color: @sky;
      .questions-on-bg-color;
  }
  
  &.block-bg-lightpink {
      background-color: @lightpink;
      .questions-on-bg-color;
  }
  
  &.block-bg-midnightblue {
      background-color: @midnightblue;
      .questions-on-bg-color;
  }
  
  &.block-bg-lightkaki {
      background-color: @lightkaki;
      .questions-on-bg-color;
  }
  
  &.block-bg-lightseegreen {
      background-color: @lightseegreen;
      .questions-on-bg-color;
  }
  
  &.block-bg-salmon {
      background-color: @salmon;
      .questions-on-bg-color;
  }

  &.block-fontColor-inverted {
    color: #fff;
    .block__title, .component__title {
      color: #fff;
    }
    .matching-item__title,
    .mcq-item__label, 
    .mcq-item__label:hover, 
    .mcq-item__label.is-disabled,
    .mcq-item.is-correct .mcq-item__label.is-selected,
    .mcq-item.is-incorrect .mcq-item__label.is-selected,
    .mcq-item__label:not(.is-disabled):not(.is-selected):hover,
    .gmcq-item__option, 
    .gmcq-item__label:hover, 
    .gmcq-item.is-correct .gmcq-item__label.is-selected .gmcq-item__option,
    .gmcq-item.is-incorrect .gmcq-item__label.is-selected .gmcq-item__option,
    .gmcq-item__label:not(.is-disabled):not(.is-selected):hover,
    .gmcq-item__label:not(.is-disabled):not(.is-selected):hover .gmcq-item__option,
    .gmcq-item__label.is-selected .gmcq-item__option,
    .gmcq-item__label.is-disabled .gmcq-item__option {
      color: #fff;
    }
  }
}