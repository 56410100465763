.text {
  &.is-pull-quote &__title {
    .pull-quote-title;
  }

  &.is-pull-quote &__body {
    .pull-quote-body;
  }

  &__body-inner {
    p {
      margin-top: 0;
    }
  }
}
