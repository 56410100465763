.graphic {
  &.has-img-zoom &__image-container {
    .img-zoom();
  }

  &__image {
    min-width: unset;
    margin: auto;
  }
}
