.inline-feedback {
  margin-top: 1rem;
  padding: @detailed-feedback-inline-padding-ver @detailed-feedback-inline-padding-hoz;
  &__inner {
    padding: @detailed-feedback-inline-padding-ver @detailed-feedback-inline-padding-hoz;
  }
}

.btn__feedback {
  &.detailed-feedback__hide-fb-btn {
    display: none;
  }
}