.resources {
  &__filter-btn {
    padding: @item-padding (@item-padding / 2);
    .resources-filter-btn;
    background-color: @drawer-item;
    border-bottom: 1px solid @drawer-item-hover;
    border-right: 1px solid @drawer-item-hover;
    color: @drawer-item-inverted;

    .no-touch &:not(.is-disabled):not(.is-selected):hover {
      background-color: @drawer-item-hover;
      color: @drawer-item-inverted-hover;
      .transition(background-color @duration ease-in, color @duration ease-in;);
    }

    &:last-child {
      border-right: none;
    }
  }

  &__filter-btn.is-selected {
    background-color: @drawer-item-selected;
    color: @drawer-item-inverted-selected;
  }

  &__item-btn {
    text-decoration: none;
  }
}
