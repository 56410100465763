// Variables:
@custom-page-title-padding: 1.25rem 1.25rem 0.6rem 1.25rem;
@custom-page-body-padding: 0 1.25rem 0.3rem 1.25rem;

// Classes:
.page {
  &.title-body-highlight-bck {
    .page__header {
      .page__header-inner {
        background-color: @item-color;
        margin-bottom: 1.5rem;
        .page__title {
          box-shadow: none;
          padding: @custom-page-title-padding;
          margin-bottom: 0;
          color: @item-color-inverted;
        }
        .page__subtitle {
          padding: @custom-page-body-padding;
          color: @item-color-inverted;
        }
        .page__body {
          margin: 0;
          padding: @custom-page-body-padding;
          color: @item-color-inverted;
        }
      }
    }
  }

  &.white-text {
    color: #fff;
    .page__title,    
    .article__title,
    .block__title, 
    .component__title,
    .page__instruction,
    .article__instruction,
    .block__instruction,
    .component__instruction  {
      color: #fff;
    }
    .component__body .component__body-inner a,
    .block__body .block__body-inner a,
    .article__body .article__body-inner a,
    .page__body .page__body-inner a  {
      color: white;
      &:visited {
        color: white;
      }
    }
    .component {
      &.tabs {
        .tabs__nav-item-btn:not(.is-selected), .tabs__content-item-inner {
          color: white;
        }
        .tabs__widget.default {
          .tabs__nav-item-btn {
            color: white;
          }
        }
      }
    }
  }

  &.invert-button-colors {
    .btn {
      background-color: @btn-color-inverted;
      color: @btn-color;
      &:hover {
        background-color: darken(@btn-color-inverted, 20%);
      }
    }
  }
}