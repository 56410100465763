@page-level-progress-height: @progress-height;
@page-level-progress-dots-hover: @nav-progress-hover;
@page-level-progress-dots-text-hover: @font-color-inverted;
@page-level-progress-dots-inverted-hover: @nav-progress-inverted-hover;
@page-level-progress-dots-locked-color: @disabled-inverted;
@page-level-progress-dots-border-radius: @item-border-radius;

// Allows the progress lozenge to appear alongside the title
// --------------------------------------------------
.js-heading + .pagelevelprogress__indicator {
  display: inline-block;
  vertical-align: middle;
  margin-right: .5rem;
  width: 1.5rem;
}

.dir-rtl {
  .js-heading + .pagelevelprogress__indicator {
    margin-right: 0;
    margin-left: .5rem;
  }
}

// Global progress lozenge
// --------------------------------------------------
.pagelevelprogress-percentage {
  position: absolute;
}
.pagelevelprogress {
  &__indicator {
    width: 5rem;
    border: 0.0625rem solid @progress-border;
    border-radius: @nav-progress-border-radius;
  }
  
  &__indicator-inner {
    background-color: @progress-inverted;
    height: @page-level-progress-height;
    border-radius: @nav-progress-border-radius;
  }

  &__indicator-bar {
    background-color: @progress;
    .transition(width @progress-duration linear);
  }
}

// Menu overrides
// --------------------------------------------------
.menu-item__progress {
  .pagelevelprogress__indicator {
    border-color: @menu-item-progress-border;
  }

  .pagelevelprogress__indicator-inner {
    background-color: @menu-item-progress-inverted;
  }

  .pagelevelprogress__indicator-bar {
    background-color: @menu-item-progress;
  }
}

.map-menu-item__progress {
  .js-menu-item-progress.expanded {
    .pagelevelprogress__indicator {
      border: 1px solid @progress-border;
    }
  }
}

// Drawer overrides
// --------------------------------------------------

.drawer__item-btn {
  .drawer__item-title {
    margin-bottom: 0;
  }
  .pagelevelprogress__indicator {
    border: none;
    &:before {
      .drawer-item-icon;
      display: block;
      text-align: right;
      .dir-rtl & {
        text-align: left;
      }
    }
    &.is-incomplete {
      .pagelevelprogress__indicator-inner {
        display: none;
      }
      &:before {
        content: "\e9aa";
        color: @nav-progress;
      }
      margin: 0;
    }
    &.is-complete {
      .pagelevelprogress__indicator-inner {
        display: none;
      }
      &:before { 
        content: "\e997";
        color: @nav-progress;
      }
      margin: 0;
    }
  }
}

// Dots
// -------------------------------------------------
.pagelevelprogressdots {
  &__item {
    .drawer__item-btn {
      &.is-disabled {
        .is-incomplete {
          .pagelevelprogress__indicator-inner {
            display: none;
          }
          &:before { 
            content: "\e9B5";
            color: @page-level-progress-dots-locked-color;
          }
          margin: 0;
        }
      }
      &::before {
        color: @nav-progress;
      }
    }
    &.is-assessment {
      .is-incomplete {
        .pagelevelprogress__indicator-inner {
          display: none;
        }
        &:before { 
          content: "\e944";
          color: @nav-progress;
        }
        margin: 0;
      }
    }
  }
}
.no-touch .pagelevelprogressdots__item-btn.drawer__item-btn.optional-dot {
  .pagelevelprogressdots__item-optional {
    background-color: @nav-progress-inverted;
  }
}

// Nav overrides
// --------------------------------------------------
.pagelevelprogress__nav-btn {
  padding: 1.5rem (@icon-size / 2);
  
  .pagelevelprogress__indicator {
    border-color: @nav-progress-border;
  }

  .pagelevelprogress__indicator-inner {
    background-color: @nav-progress-inverted;
  }

  .pagelevelprogress__indicator-bar {
    background-color: @nav-progress;
  }

  .no-touch &:hover {
    .pagelevelprogress__indicator {
      border-color: @nav-progress-border-hover;
      .transition(border-color @duration ease-in);
    }

    .pagelevelprogress__indicator-inner {
      background-color: @nav-progress-inverted-hover;
      .transition(background-color @duration ease-in);
    }

    .pagelevelprogress__indicator-bar {
      background-color: @nav-progress-hover;
      .transition(background-color @duration ease-in);
    }
  }
  .pagelevelprogress-percentage {
    display: none;
  }
}

.pagelevelprogress__full-witdh {
  .pagelevelprogress__indicator {
    border-right: 0;
    border-left: 0;
  }
  .pagelevelprogress__indicator-bar {
    border-top-right-radius: @nav-progress-border-radius;
    border-bottom-right-radius: @nav-progress-border-radius;
  }
}

.pagelevelprogress__item-btn.is-disabled {
  .pagelevelprogress__indicator {
    border-color: @disabled-inverted;
  }

  .pagelevelprogress__indicator-inner {
    background-color: @disabled;
  }

  .pagelevelprogress__indicator-bar {
    background-color: @disabled-inverted;
  }
}

.pagelevelprogress__indicator {
  &.is-page {
    .pagelevelprogress-percentage {
      display: none;
    }
  }
  &.is-article {
    .pagelevelprogress-percentage {
      display: none;
    }
  }
  &.is-block {
    .pagelevelprogress-percentage {
      display: none;
    }
  }
  &.is-component {
    .pagelevelprogress-percentage {
      display: none;
    }
  }
}
