// PLP nav bar button
// --------------------------------------------------
.nav {
  &__pagelevelprogress-btn {
    .u-float-right;
    padding: @icon-size (@icon-size / 2);
  }
}

// PLP drawer
// --------------------------------------------------
.drawer .pagelevelprogress {
  &__item-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item-title {
    width: 75%;
  }

  &__indicator {
    margin: 0 calc(~'12.5% - 1rem');
  }

  &__item-optional {
    width: 5rem;
  }

  &__item-optional-inner {
    font-size: 0.75rem;
  }
}
