// Create a scrollable horizontal and vertically centered container
// --------------------------------------------------
.l-fill-viewport-absolute() {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

// Create a fixed horizontal and vertically centered container
// --------------------------------------------------
.l-fill-viewport-fixed() {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

// Mixin to generate a variable width horizontally centered container
// --------------------------------------------------
.l-container-responsive(@size) {
  margin: 0 auto;
  max-width: @size;
}

// Mixin to provide responsive container padding
// --------------------------------------------------
.l-container-padding(@padding-ver, @padding-hoz) {
  padding: @padding-ver @padding-hoz;
}
