//sizes
@slide-show-mobile-image-height: 15.625rem;
@slide-show-full-image-slide-height: 28.125rem;
@slide-show-dots-button-size: 0.625rem;
@slide-show-arrow-position: -65px;

//paddings
@slide-show-slide-padding: 1.25rem;

//margins
@slide-show-slide-image-margin: 1rem;
@slide-show-title-margin: 1rem;
@slide-show-text-margin-top: 1rem;

//colors
@slide-show-dot-border-color: #3b87d4;
@slide-show-dot-background-color: @slide-show-dot-border-color;

//mixins
.OnSmallScreens(@rules) { @media all and (max-width: @device-width-small){ @rules(); }};
.OnMediumScreens(@rules) { @media all and (max-width: @device-width-medium){ @rules(); }};
.slide-items-width(@slide-image, @slide-text) {
  .slide-show {
    &__slide-image {
      width: @slide-image;
      .OnSmallScreens({
        width: 100%;
      });
      .slide-show__slide-image-inner {
        height: 100%;
        position: relative;
        &.fit-image {
          background-size: contain;
        }
        .OnSmallScreens({
          height: @slide-show-mobile-image-height;
          background-size: auto;
        });
      }
    }
    &__slide-text {
      width: @slide-text;
      .OnSmallScreens({
        width: 100%;
        margin-top: @slide-show-text-margin-top;
      });
    }
  }
}
.slide-items-position(@direction, @margin-left, @margin-right) {
  .slide-show__slide-inner {
    flex-direction: @direction;
    .slide-show__slide-image {
      margin-right: @margin-right;
      margin-left: @margin-left;
      .OnSmallScreens({
        margin-left: 0;
      })
    }
  }
}
.text-container-settings(@vertical-position, @horizontal-position) {
  .slide-show__slide-image-content {
    align-items: @vertical-position;
    justify-content: @horizontal-position;
    .slide-show__slide-image-text {
      display: block;
      overflow: auto;
      margin: 2.5%;
      @media all and (-ms-high-contrast:none) {
        overflow: hidden;
      }
      .OnSmallScreens({
        display: none;
      });
    }
  }
}
.pressedAnimation(@offset) {
  &:active {
    transform: translateY(@offset);
  }
}