.component {
  width: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;

    @media (min-width: @device-width-medium) {
      flex-direction: row;
    }
  }

  @media (min-width: @device-width-medium) {
    &.is-left {
      width: 50%;
    }

    &.is-right {
      margin-left: auto;
      width: 50%;

      .dir-rtl & {
        margin-left: inherit;
        margin-right: auto;
      }
    }
  }
}
