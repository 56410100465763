.slide-show {
  &__slide-image {
    .slide-show__slide-image-inner {
      background-repeat: no-repeat;
      position: relative;
      .OnSmallScreens({
        height: @slide-show-mobile-image-height;
        background-size: cover;
      });
      &.fit-image {
        background-size: cover;
      }
    }
  }
  &__slide-image-text {
    display: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  &__slide-text {
    overflow: auto;
    box-sizing: border-box;
    .slide-show__slide-text-inner {
      padding: @slide-show-slide-padding;
      .OnSmallScreens({
        &.arrow-mobile-padding {
          padding-bottom: @slide-show-slide-padding+3.4;
        }
      });
      p {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
    .OnSmallScreens({
      margin-top: @slide-show-text-margin-top;
    });
  }
  &__slide {
    &.grab-cursor {
      width: 100%;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
    .slide-show__slide-inner {
      display: flex;
      flex-direction: row;
      .OnSmallScreens({
        display: block;
        height: 100% !important;
      });
      .slide-show__slide-image-text-content, .slide-show__slide-text-content {
        margin-bottom: @slide-show-title-margin;
        margin-top: 0;
      }
    }
    &.is-split-slide-content {
      .slide-show__slide-image-content {
        display: none;
      }
      &.ratio-50-50 {
        .slide-items-width(50%, 50%);
      }
      &.ratio-20-80 {
        .slide-items-width(20%, 80%);
      }
      &.ratio-30-70 {
        .slide-items-width(30%, 70%);
      }
      &.ratio-80-20 {
        .slide-items-width(80%, 20%);
      }
      &.ratio-70-30 {
        .slide-items-width(70%, 30%);
      }
      &.left {
        .slide-items-position(row, 0, @slide-show-slide-image-margin);
      }
      &.right {
        .slide-items-position(row-reverse, @slide-show-slide-image-margin, 0);
      }
      .is-inline-image {
        display: none;
      }
    }
    &.is-top-bottom-content {
      .slide-show__slide-image-content {
        display: none;
      }
      .slide-show__slide-text {
        align-self: flex-start;
        width: 100%;
      }
      &.top {
        .slide-show__slide-inner {
          flex-direction: column;
          align-items: center;
          .slide-show__slide-image {
            margin-bottom: @slide-show-slide-image-margin;
          }
        }
      }
      &.bottom {
        .slide-show__slide-inner {
          flex-direction: column-reverse;
          align-items: center;
          .slide-show__slide-image {
            margin-top: @slide-show-slide-image-margin;
          }
        }
      }
      &.is-mobile {
        .is-inline-image {
          display: none;
        }
      }
    }
    &.is-full-image-slide {
      .slide-show__slide-inner {
        display: block;
        .slide-show__slide-image {
          .slide-show__slide-image-inner {
            background-size: cover;
            position: relative;
            .is-inline-image {
              display: none;
            }
            .slide-show__slide-image-content {
              width: 100%;
              min-height: @slide-show-full-image-slide-height;
              display: flex;
              position: relative;
              .slide-show__slide-image-text {
                .slide-show__slide-image-text-inner {
                  padding: @slide-show-slide-padding @slide-show-slide-padding 0 @slide-show-slide-padding;
                  p {
                    margin-top: 0;
                  }
                }
              }
            }
            &.fit-image {
              background-size: contain;
            }
          }
        }
        .slide-show__slide-text {
          display: none;
          .OnSmallScreens({
            display: block;
            margin-top: @slide-show-text-margin-top;
          });
        }
      }

      // Vertical alignment of text container which is 100% of image width
      &.text-vertical-bottom {
        .text-container-settings(flex-end, '');
      }
      &.text-vertical-center {
        .text-container-settings(center, '');
      }
      &.text-vertical-top {
        .text-container-settings(flex-start, '');
      }

      // Horizontal alignment of text container which is 100% of image height
      &.text-horizontal-left {
        .text-container-settings('', flex-start);
      }
      &.text-horizontal-right {
        .text-container-settings('', flex-end);
      }
      &.text-horizontal-center {
        .text-container-settings('', center);
      }

      // All positions
      &.text-top-left {
        .text-container-settings('', '');
      }
      &.text-center-left {
        .text-container-settings(center, '');
      }
      &.text-bottom-left {
        .text-container-settings(flex-end, '');
      }
      &.text-top-center {
        .text-container-settings(flex-start, center);
      }
      &.text-center-center {
        .text-container-settings(center, center);
      }
      &.text-bottom-center {
        .text-container-settings(flex-end, center);
      }
      &.text-top-right {
        .text-container-settings(flex-start, flex-end);
      }
      &.text-center-right {
        .text-container-settings(center, flex-end);
      }
      &.text-bottom-right {
        .text-container-settings(flex-end, flex-end);
      }
    }
  }
  &__widget-items {
    .slide-show__maximum-slides-warning {
      color: #FF0000;
      text-align: center;
    }
    &.arrows-enabled {
      width: 85%;
      margin: 0 auto;
    }
  }

  .arrow-next {
    background-image: url("assets/ico_arrow_open_right_white.svg");
    background-repeat: no-repeat;
    background-position: center 50%;
    position: absolute;
    cursor: pointer;
    right: @slide-show-arrow-position;
    top: 50%;    
    .pressedAnimation(0.125rem);

    &.btn-icon-circle:hover {
      transition: background-color 0.5s;
    }
    &.rtl {
      left: @slide-show-arrow-position;
      right: unset;
      background-image: url("assets/ico_arrow_open_left_white.svg");
    }
    &.slick-disabled {
      display: none !important; //override property to hide prev arrow on first slide
    }
    .OnSmallScreens({
      right: 0.3rem;
      z-index: 2;
      top: 100%;
    });
  }

  .arrow-prev {
    background-image: url("assets/ico_arrow_open_left_white.svg");
    background-repeat: no-repeat;
    background-position: center 50%;
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: @slide-show-arrow-position;
    .pressedAnimation(0.125rem);
    &.btn-icon-circle:hover {
      transition: background-color 0.5s;
    }
    &.rtl {
      right: @slide-show-arrow-position;
      left: unset;
      background-image: url("assets/ico_arrow_open_right_white.svg");
    }
    &.slick-disabled {
      display: none !important; //override property to hide prev arrow on first slide
    }
    .OnSmallScreens({
      left: 0.3rem;
      z-index: 2;
      top: 100%;
    });
  }

  /* Slider */
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    .slick-track {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .slick-list {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    &:focus {
      outline: none;
    }
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    &:before {
      display: table;
      content: '';
    }
    &:after {
      display: table;
      content: '';
      clear: both;
    }
  }
  .slick-loading {
    .slick-track {
      visibility: hidden;
    }
    .slick-slide {
      visibility: hidden;
    }
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    //padding: 0 5px;
    img {
      display: block;
    }
  }
  [dir='rtl'] {
    .slick-slide {
      float: right;
    }
  }
  .slick-slide.slick-loading {
    img {
      display: none;
    }
  }
  .slick-slide.dragging {
    img {
      pointer-events: none;
    }
  }
  .slick-initialized {
    .slick-slide {
      display: block;
    }
  }
  .slick-vertical {
    .slick-slide {
      display: block;
      height: auto;
      border: 0.1rem solid transparent;
    }
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  // Styling the default Slick dots
  .slide-show__widget {
    &.has-no-image {
      .slick-dots {
        .OnSmallScreens({
          top: 0;
        });
      }
      .slide-text {
        .OnSmallScreens({
          margin-top: 1.875rem !important;
        });
      }
    }
  }
  .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.94rem 0;
    list-style-type: none;
    overflow: hidden;
    li {
      margin: 0 0.25rem;
    }
    button {
      display: block;
      width: @slide-show-dots-button-size;
      height: @slide-show-dots-button-size;
      padding: 0;
      border: 0.01rem solid @slide-show-dot-border-color;
      border-radius: 100%;
      background-color: transparent;
      text-indent: -625rem;
      &:hover {
        background-color:fade(@slide-show-dot-background-color, 10%);
        border: 0.1rem solid fade(@slide-show-dot-background-color, 10%);
      }
      &:focus {
        outline: none;
      }
    }

    li.slick-active button {
      background-color: @slide-show-dot-background-color;
      border-color: @slide-show-dot-border-color;
    }
    .OnSmallScreens({
      position: absolute;
      top: 16rem;
      width: 100%;
    });
  }
  .slide-show__widget-items {
    &.dots-desktop {
      &-bottom {
        padding-top: 0;
        .slick-dots {
          position: relative;
        }
      }
      &-top {
        padding-top: 50px;
        .slick-dots {
          top: 0;
          width: 100%;
          position: absolute;
        }
      }
    }
    &.dots-mobile {
      &-bottom {
        .OnSmallScreens({
          padding-top: 0;
          .slick-dots {
              position: relative;
          }
        });
      }
      &-top {
        .OnSmallScreens({
          padding-top: 50px;
          .slick-dots {
            top: 0;
            position: absolute;
          }
        });
      }
    }
    &.dot-nrs-enabled {
      .slick-dots {
        button {
          width: 1.5rem;
          height: 1.5rem;
          text-indent: revert;
          font-size: 12px;
          color: @font-color;
        }
        li.slick-active button {
          color: @font-color-inverted;
        }
      }
    }
  }
}
