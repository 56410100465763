// Global button styles
// --------------------------------------------------
.btn-icon {
  padding: @icon-padding;
  border: 0;
}

.btn-text {
  padding: (@btn-padding / 2) @btn-padding;
}

// Question component buttons
// --------------------------------------------------
.btn {
  &__response-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }

  &__action:not(.is-full-width) {
    width: calc(~'50% - @{btn-padding}');
    margin-right: @btn-padding;

    .dir-rtl & {
      margin-right: inherit;
      margin-left: @btn-padding;
    }
  }

  &__feedback:not(.is-full-width) {
    width: calc(~'50% - @{btn-padding}');
    margin-left: @btn-padding;

    .dir-rtl & {
      margin-left: inherit;
      margin-right: @btn-padding;
    }
  }

  &__action.is-full-width,
  &__feedback.is-full-width {
    width: 100%;
  }

  .can-show-marking &__action.is-full-width,
  .can-show-marking &__feedback.is-full-width {
    margin-right: @icon-size + (@item-margin * 2);

    .dir-rtl & {
      margin-right: inherit;
      margin-left: @icon-size + (@item-margin * 2);
    }
  }

  // Button marking
  // --------------------------------------------------
  &__marking {
    position: absolute;
    left: 50%;
    top: 50%;
    .transform(translate(-50%, -50%));

    .dir-rtl & {
      left: inherit;
      right: 50%;
      .transform(translate(50%, -50%));
    }
  }

  &__marking.is-full-width {
    left: inherit;
    right: @item-margin;
    .transform(translate(0, -50%));

    .dir-rtl & {
      left: @item-margin;
      right: inherit;
    }
  }

  // Button marking icon states
  // --------------------------------------------------
  &__marking.is-correct {
    background-color: @validation-success;
    color: @validation-success-inverted;
  }

  &__marking.is-correct .icon {
    .icon-tick;
  }

  &__marking.is-incorrect {
    background-color: @validation-error;
    color: @validation-error-inverted;
  }

  &__marking.is-incorrect .icon {
    .icon-cross;
  }
}
