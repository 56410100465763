.nav {
  background-color: @nav;
  z-index: 91;

  &__inner {
    max-width: inherit;
    position: relative;
    height: @navigation-height;
  }

  &__btn {
    background-color: @nav-icon;
    color: @nav-icon-inverted;

    .no-touch &:not(.is-disabled):not(.is-locked):hover {
      background-color: @nav-icon-hover;
      color: @nav-icon-inverted-hover;
      .transition(background-color @duration ease-in, color @duration ease-in;);
    }
  }

  &__btns-left {
    float: left;
    display: flex;
    align-items: center;
    max-height: 100%;
    min-height: 100%;
  }

  &__btns-right {
    float: right;
    display: flex;
    align-items: center;
    max-height: 100%;
    min-height: 100%;
  }

  &__logoImage {
    position: absolute;
    height: 100%;
    width: 70%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    pointer-events: none;
    img {
      height: auto;
      max-height: @navigation-height;
      min-width: unset;
      margin-left: auto;
      margin-right: auto;
      padding: 0.3rem;
    }
  }

  &__drawer-btn {
    float: none;
    order: 1;
  }

  &__home-btn .icon {
    .icon-home;
  }

  &__sticky-menu-container {
    position: fixed;
    display: flex;
    margin: 2rem;
    &.top-left {
      flex-direction: column;
      @media (max-width: 64.25rem) {
        bottom: 0;
        top: unset;
        flex-direction: column-reverse;
      }
    }
    &.top-right {
      right: 0;
      top: 0;
      flex-direction: column;
      align-items: flex-end;
      .sticky-menu-content-btn-right(.5rem, 0);
      @media (max-width: 64.25rem) {
        bottom: 0;
        top: unset;
        flex-direction: column-reverse;
      }
    }
    &.bottom-right {
      right: 0;
      bottom: 0;
      top: unset;
      flex-direction: column-reverse;
      align-items: flex-end;
      .sticky-menu-content-btn-right(.5rem, 0);
    }
    &.bottom-left {
      bottom: 0;
      top: unset;
      flex-direction: column-reverse;
    }
    .nav__sticky-menu-button {
      &.isOpen {
        .icon {
          .icon-cross;
        }
      }
      &.isOpen + .nav__sticky-menu-content {
        display: flex;
      }
    }
    .nav__sticky-menu-content {
      display: none;
      margin: 0.35rem;
      padding: .5rem;
      background-color: @background;
      border-radius: @item-border-radius;
      flex-direction: column;
    }
    .nav__sticky-menu-content-btn {
      padding: .5rem;
      flex-direction: row;
      display: flex;
      align-items: center;
      .icon {
        background-color: @background;
        box-shadow: 0px 2px 4px gray;
        padding: 0.5rem;
        border-radius: 20px;
        margin-right: 0.5rem;
      }
      &:hover {
        .icon {
          background-color: darken(@background, 4%);
        }
        .nav__sticky-menu-content-title {
          font-weight: @font-weight-semibold;
        }
      }
    }

  }
}

.sticky-menu-content-btn-right(@margin-left, @margin-right) {
  .nav__sticky-menu-content-btn {
    flex-direction: row-reverse;
    width: 100%;
    justify-content: end;
    .icon {
      margin-left: @margin-left;
      margin-right: @margin-right;
    }
  }
}
