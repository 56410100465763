.info-graphic {
  &__tick {
    display: none;
    min-width: unset;
  }
  
  &__tick-image {
    position: absolute;
    top: 0;
    margin:0;
    display: none;
  }

  &__lock-image {
    position: absolute;
    top: 0;
    margin:0;
    display: none;
  }

  &__lock {
    min-width: unset;
    &.icon {
      top: -0.5rem;
      position: absolute;
      &:before {
        font-size: 1.7rem;
      }
    }
  }

  &__header {
    &.hide {
      display: none;
    }
  }
  
  &__fullscreen {
    .OnMobile({
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 91;
    overflow-y: scroll;
    background-color: @info-graphic-mobile-fullscreen-background-color;
    });
  }

  &__widget {
    position: static;
    .OnMobile({
      &.is-fullscreen {
        overflow: scroll;
      }
    });
  }

  &__graphic {
    display: flex;
    justify-content: center;
    &.is-fullscreen {
      width: 250%;
      margin-top: 20%;
    }
  }
  
  &__graphic-container {
    position: relative;
    display: flex;
    .info-graphic__graphic-container-content {
      .info-graphic__scroll-to {
        position: absolute;
        top: -200px;
      }
      .info-graphic__main-graphic {
        .info-graphic__main-graphic-image {
          width: 100%;
          height: auto;
        }
        //used for ios devices
        @media not all and (min-resolution: .001dpcm) {
          @supports (-webkit-appearance: none) {
            display: block;
          }
        }
      }
      .OnMobile({
        &.is-fullscreen {
          max-width: unset;
          margin: unset;
        }
      });

      .info-graphic__enter-fullscreen-button, .info-graphic__close-fullscreen-button {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        margin: 1.25rem 1.5rem 0 0;
        z-index: 79;
        &:before {
          font-size: 1.5rem;
        }
      }
      .info-graphic__enter-fullscreen-button {
        &.hide {
          display: none;
        }
        .OnMobile({
          display: block;
        });
      }
      .info-graphic__close-fullscreen-button {
        &.show {
          .OnMobile({
            display: block;
            position: fixed;
          });
        }
      }
    }
    // IE11 fallback view
    @media all and (-ms-high-contrast:none) and (max-width:@device-width-small) {
      .info-graphic__graphic-container-content {
        position: static;
        height: 0;
        width: 0;
      }
    }
  }

  &__graphic-pin {
    display: block;
    position: absolute;
    text-decoration: none;
    z-index: 79;
    overflow: visible;
    &.is-visited.is-static-content {
      cursor: default;
      pointer-events: none;
      .info-graphic__tick {
        display: none;
      }
      .info-graphic__lock {
        display: none;
      }
    }
    &:focus {
      outline: none;
    }
    .info-graphic__square-content {
      height: 100%;
      padding: 0.3rem 0.625rem;
      display: flex;
      overflow: hidden;
      text-align: left;
      flex-direction: column;
      &.top {
        justify-content: flex-start;
        p {
          margin: 1em 0 0 0;
        }
      }
      &.middle {
        justify-content: center;
        p {
          margin: 0.5em 0 0.5em 0;
        }
      }
      &.bottom {
        justify-content: flex-end;
        p {
          margin: 0 0 1em 0;
        }
      }
      p {
        width: 100%;
      }
    }
    .info-graphic__graphic-pin-image {
      max-width: 100%;
      position: relative;
      display: inline-block;
      .info-graphic__graphic-pin-image-content {
        display: unset;
      }
      .info-graphic__tick-image {
        display: none;
      }
      .info-graphic__tick-image {
        display: none;
      }
      .info-graphic__pin-graphic {
        max-width: unset;
      }
    }

    &.has-pin-image {
      background-color: transparent !important;
      border-radius: 0;
      line-height: 0;
      outline: none;
      overflow: visible;
    }

    &:active {
      transform: translate(1px, 1px);
    }

    &.is-visited {
      .info-graphic__tick {
        display: inline-block;
        &.icon {
          top: -0.5rem;
          position: absolute;
          &:before {
            font-size: 1.7rem;
          }
        }
      }
    }
  }

  &.is-complete {
    .info-graphic__tick {
      display: inline-block;
      top: -0.5rem;
      position: absolute;
      &.left {
        left: -0.3rem;
      }
      &.right {
        right: -0.3rem;
      }
    }
    .inview-completion {
      &.is-visited {
        .info-graphic__tick {
          display: inline-block;
        }
      }
      .info-graphic__tick {
        display: none;
      }
    }
  }

  &__popup-item:not(.is-active) {
    display: none;
  }
}
