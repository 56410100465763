.menu-folding-map {
  &__item {
    .responsive-medium;
    .responsive-small;
    margin-top: 10px;
    width: 100%;
  }

  &__item-inner {
    padding: 1.25rem;
    position: relative;
    margin: auto;
    box-shadow: 1px 12px 22px 3px #00000080, 0px 0px 4px -2px #00000080;
    .menu-folding-map__item-graphic-mobile {
      display: none;
    }
    .OnSmallScreens ({
      .menu-folding-map__item-graphic-mobile {
        display: block;
      }
      .menu-folding-map__item-graphic {
        display: none;
      }
    });
    .menu-folding-map__item-graphic {
      position: absolute;
      float: left;
      width: 70px;
    }
    .menu-folding-map__item-graphic-mobile {
      position: absolute;
      float: left;
      .OnSmallScreens ({
        width: 70px;
      });
    }
  }

  &__item:last-of-type {
    .menu-folding-map__item-graphic, .menu-folding-map__item-graphic-mobile {
      background-color: inherit;
    }
    .menu-folding-map__item-track-mask {
      top:15%;
      height: 85%;
    }
  }
  &__item:first-of-type {
    .menu-folding-map__item-track-mask {
      height: 25px;
    }
    &.menu-folding-map__item:last-of-type {
      .menu-folding-map__item-track-mask {
        height: 100%;
        top: 0;
      }
    }
  }

  &__item-track-mask {
    width: 70px;
    height: 0%;
    background: inherit;
    position: absolute;
    top: 0px;
    .OnSmallScreens ({
      width: 15%;
    });
  }
  &__item-track {
    position: absolute;
    height: 100%;
    width: 70px;
    top: 0;
    background-image: url(assets/mapBg.png);
    background-repeat: repeat-y;
    background-position: 45% -50px;
    .OnSmallScreens ({
      width: 20%;
    });
  }

  &__item-title {
    min-height: 1.5rem;
    word-break: break-word;
    display: flex;
    background-color:inherit;
    align-items: center;
    .menu-folding-map__item-title-inner {
      display: flex;
      align-items: center;
      text-align: start;
      .menu-item-title();
      font-size: 1.25rem;
    }
  }

  &__item-body {
    .menu-folding-map__item-body-inner {
      padding: 5px 0;
      p {
        margin: 0;
      }
    }
  }

  &__item-duration-inner {
    min-height: 25px;
    display: flex;
    padding: 5px 0;
    flex-direction: row;
    align-items: center;
  }
  &__duration-icon {
    margin-right: 5px;
  }
  &__duration-text {
    margin: 0;
  }
  &__custom-duration-icon {
    max-width: 25px;
    height: auto;
  }

  &__item-progress-bar {
    .js-menu-item-progress {
      padding: 10px 0;
    }
    .pagelevelprogress__indicator {
      width: 100%;
      .pagelevelprogress__indicator-bar {
        min-width: 0;
      }
    }
  }

  &__item-progress-bar,
  &__item-duration,
  &__item-body,
  &__item-title {
    margin-left: 100px;
    .OnSmallScreens ({
      margin-left: 80px;
    });
    .dir-rtl & {
      margin-left: 0;
      margin-right: 120px;
      .OnSmallScreens ({
        margin-left: 0;
        margin-right: 80px;
      });
    }
  }
  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    .item-circle(@folding-map-menu-circle-color, @folding-map-menu-circle-color-inverted); 
    .menu-folding-map__icon-complete {
      .icon-tick {
        color: @folding-map-menu-complete-icon-color;
        &:before {
          font-size: 2.5rem;
        }
      }
      @media not all and (min-resolution: .001dpcm) {
        @supports (-webkit-appearance: none) {
          margin: 11px 0 0 10px;
        }
      }
    }
    .menu-folding-map__icon-locked {
      .icon-padlock-locked {
        color: @folding-map-menu-locked-icon-color;
        &:before {
          font-size: 2.5rem;
        }
      }
      // iOS Safari only
      @media not all and (min-resolution: .001dpcm) {
        @supports (-webkit-appearance: none) {
          margin: 10px 0 0 14px;
        }
      }
    }
  }
  &__chapter-number {
    font-size: 2em;
  }

  .fold-transition {
    -webkit-transition: margin-top 0.6s linear 0s,
      -moz-transform 0.6s linear 0.03s,
      -webkit-transform 0.6s linear 0.03s,
      transform 0.6s linear 0.03s;
    -moz-transition: margin-top 0.6s linear 0s,
      -moz-transform 0.6s linear 0.03s,
      -webkit-transform 0.6s linear 0.03s,
      transform 0.6s linear 0.03s;
    transition: margin-top 0.6s linear 0s,
      -moz-transform 0.6s linear 0.03s,
      -webkit-transform 0.6s linear 0.03s,
      transform 0.6s linear 0.03s;
  }

  .fold:nth-child(odd) {
    -webkit-transform: perspective(700px) rotateX(80deg);
    -moz-transform: perspective(700px) rotateX(80deg);
    transform: perspective(700px) rotateX(80deg);

    .menu-folding-map__item-inner {
      background-color: @folding-map-menu-background-color;
      &:hover{
        cursor: pointer;
        background-color: darken(@folding-map-menu-background-color,2%);
      }
    }
  }

  .fold:nth-child(even) {
    -webkit-transform: perspective(700px) rotateX(-80deg);
    -moz-transform: perspective(700px) rotateX(-80deg);
    transform: perspective(700px) rotateX(-80deg);

    .menu-folding-map__item-inner {
      background-color: darken(@folding-map-menu-background-color,2%);
      &:hover{
        cursor: pointer;
        background-color: darken(@folding-map-menu-background-color,4%);
      }
    }
  }
}