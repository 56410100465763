.notify-push {
  position: fixed;
  right: 0;
  width: @drawer-width;
  opacity: 0;
  z-index: 100;
  background-color: @background-inverted;

  &.is-active {
    opacity: 1;
  }

  .dir-rtl & {
    right: auto;
    left: 0;
  }

  &__inner {
    cursor: pointer;
  }

  // Notify push icon
  // --------------------------------------------------
  &__close-btn .icon {
    .icon-cross;
  }
}
