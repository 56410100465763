.matching {
  &__widget.is-disabled &-item__select-icon {
    color: @disabled-inverted;
  }

  // Error highlight
  // --------------------------------------------------
  &__widget:not(.is-complete) &-item__select-container.has-error .dropdown__btn {
    box-shadow: 0 0 0 1px @validation-error inset;
  }

  &__widget {
    .matching-item {
      &.is-correct {
        .dropdown {
          .dropdown__btn.is-disabled {
            background-color: fade(@validation-success, 60%);
            color: @font-color;
          }
        }
        .matching-item__select-icon {
          color: @font-color;
        }
      }
      &.is-incorrect {
        .dropdown {
          .dropdown__btn.is-disabled {
            background-color: fade(@validation-error, 60%);
            color: @font-color;
          }
        }
        .matching-item__select-icon {
          color: @font-color;
        }
      }
    }
  }
}

.matching-item {
  margin-bottom: @item-margin * 1.5;

  &__title {
    padding: @item-padding;
    border-radius: @item-border-radius;
    background-color: lighten(@item-color, 40%);
    margin-bottom: @item-margin / 2;
  }

  &__select-container {
    position: relative;
    .dropdown__btn {
      border-radius: @item-border-radius;
    }
    .dropdown__list {
      border-radius: @item-border-radius;
    }
  }

  &__select-state {
    position: absolute;
    top: 50%;
    right: @item-padding;
    .transform(translateY(-50%));

    .dir-rtl & {
      right: inherit;
      left: @item-padding;
    }
  }
}
