.notify {
  position: relative;
  z-index: 100;

  &__popup {
    position: fixed;
    top: 50%;
    width: 100%;
    visibility: hidden;
    z-index: 100;
    background-color: @background-inverted;
  }

  &__popup-inner {
    .l-container-responsive(@device-width-large);
  }

  // Notify icons
  // --------------------------------------------------
  &__close-btn .icon {
    .icon-cross;
  }

  &__icon .icon.is-prompt {
    .icon-question;
  }

  &__icon .icon.is-alert {
    .icon-warning;
  }

  // Sets up full width title and 50/50 text/image split below
  // --------------------------------------------------
  @media (min-width: @device-width-medium) {
    &__content.has-image &__content-inner {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__content.has-image &__title {
      width: 100%;
    }

    &__content.has-image &__body,
    &__content.has-image &__image-container {
      width: 50%;
    }
  }

  // Left aligns image in notify pop up
  // --------------------------------------------------
  @media (min-width: @device-width-medium) {
    &__content.align-image-left &__content-inner {
      flex-direction: row-reverse;
    }
  }
}
