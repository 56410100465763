.pagejumper {
  &__left {
    text-align: left;
  }

  &__center {
    text-align: center;
  }

  &__right {
    text-align: right;
  }

  &__button {
    float: none;
  }
}
