.glossary {
  &__textbox-container {
    position: relative;
  }

  &__textbox {
    width: 100%;
    padding: @item-padding (@item-padding + @icon-size) @item-padding @item-padding;

    .dir-rtl & {
      padding-right: @item-padding;
      padding-left: @item-padding + @icon-size;
    }
  }

  // Hide 'X' icon for IE11 and Edge 12+
  // Issue: https://github.com/adaptlearning/adapt_framework/issues/1913
  &__textbox::-ms-clear {
    .u-display-none;
  }

  &__cancel-btn,
  &__search-icon {
    position: absolute;
    top: 50%;
    right: @item-padding / 2;
    padding: 0;
    .transform(translateY(-50%));
    background-color: transparent;

    .dir-rtl & {
      right: inherit;
      left: @item-padding / 2;
    }
  }

  &__search-icon .icon {
    .icon-search;
  }

  &__cancel-btn .icon {
    .icon-cross;
  }

  &__search-container {
    position: fixed;
    top: @navigation-height;
    z-index: 2;
  }

  &__checkbox-input {
    accent-color: @item-color-selected;
  }

  &__index-container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__index-container.scrolling-up {
    position: fixed;
    z-index: 1;
  }

  &__index-container.scrolling-down {
    position: relative;
    z-index: 0;
  }

  &__items-container,
  &__item-not-found {
    position: relative;
  }
}
