// Icons
// --------------------------------------------------
@font-face {
  font-family: "vanilla";
  src: url('fonts/vanilla.woff2') format('woff2'),
       url('fonts/vanilla.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  &:before {
    display: block;
    font-family: "vanilla";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    font-size: @icon-size;
    text-align: center;
    text-decoration: none;

    // Better font rendering
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

// Icons as classes
// --------------------------------------------------
.icon-arrow-with-circle-down      { &:before { content: "\e900"; } }
.icon-arrow-with-circle-left      { &:before { content: "\e901"; .dir-rtl & { content: "\e902"; } } }
.icon-arrow-with-circle-right     { &:before { content: "\e902"; .dir-rtl & { content: "\e901"; } } }
.icon-arrow-with-circle-up        { &:before { content: "\e903"; } }
.icon-chevron-with-circle-down    { &:before { content: "\e904"; } }
.icon-chevron-with-circle-left    { &:before { content: "\e905"; .dir-rtl & { content: "\e906"; } } }
.icon-chevron-with-circle-right   { &:before { content: "\e906"; .dir-rtl & { content: "\e905"; } } }
.icon-chevron-with-circle-up      { &:before { content: "\e907"; } }
.icon-info-with-circle            { &:before { content: "\e908"; } }
.icon-location                    { &:before { content: "\e909"; } }
.icon-medal                       { &:before { content: "\e90a"; } }
.icon-price-ribbon                { &:before { content: "\e90b"; } }
.icon-shield                      { &:before { content: "\e90c"; } }
.icon-stopwatch                   { &:before { content: "\e90d"; } }
.icon-text-document-inverted      { &:before { content: "\e90e"; } }
.icon-text-document               { &:before { content: "\e90f"; } }
.icon-trophy                      { &:before { content: "\e910"; } }
.icon-arrow-down                  { &:before { content: "\e911"; } }
.icon-arrow-left                  { &:before { content: "\e912"; .dir-rtl & { content: "\e913"; } } }
.icon-arrow-right                 { &:before { content: "\e913"; .dir-rtl & { content: "\e912"; } } }
.icon-arrow-up                    { &:before { content: "\e914"; } }
.icon-bookmark                    { &:before { content: "\e915"; } }
.icon-message                     { &:before { content: "\e916"; } }
.icon-mouse                       { &:before { content: "\e917"; } }
.icon-mobile                      { &:before { content: "\e918"; } }
.icon-desktop                     { &:before { content: "\e919"; } }
.icon-pencil                      { &:before { content: "\e91a"; } }
.icon-forward-arrow               { &:before { content: "\e91b"; .dir-rtl & { content: "\e91c"; } } }
.icon-backwards-arrow             { &:before { content: "\e91c"; .dir-rtl & { content: "\e91b"; } } }
.icon-person                      { &:before { content: "\e91d"; } }
.icon-persons                     { &:before { content: "\e91e"; } }
.icon-profile                     { &:before { content: "\e91f"; } }
.icon-pin                         { &:before { content: "\e920"; } }
.icon-map                         { &:before { content: "\e921"; } }
.icon-point                       { &:before { content: "\e922"; } }
.icon-star                        { &:before { content: "\e923"; } }
.icon-star-hollow                 { &:before { content: "\e924"; } }
.icon-comments                    { &:before { content: "\e925"; } }
.icon-comment                     { &:before { content: "\e926"; } }
.icon-home                        { &:before { content: "\e927"; } }
.icon-popup                       { &:before { content: "\e928"; } }
.icon-search                      { &:before { content: "\e929"; } }
.icon-printer                     { &:before { content: "\e92a"; } }
.icon-link                        { &:before { content: "\e92b"; } }
.icon-flag                        { &:before { content: "\e92c"; } }
.icon-cog                         { &:before { content: "\e92d"; } }
.icon-tools                       { &:before { content: "\e92e"; } }
.icon-tag                         { &:before { content: "\e92f"; } }
.icon-camera                      { &:before { content: "\e930"; } }
.icon-feedback                    { &:before { content: "\e931"; } }
.icon-book                        { &:before { content: "\e932"; } }
.icon-article                     { &:before { content: "\e933"; } }
.icon-eye                         { &:before { content: "\e934"; } }
.icon-time                        { &:before { content: "\e935"; } }
.icon-microphone                  { &:before { content: "\e936"; } }
.icon-calendar                    { &:before { content: "\e937"; } }
.icon-timer                       { &:before { content: "\e938"; } }
.icon-drag                        { &:before { content: "\e939"; } }
.icon-light-bulb                  { &:before { content: "\e93a"; } }
.icon-checklist                   { &:before { content: "\e93b"; } }
.icon-ticket                      { &:before { content: "\e93c"; } }
.icon-wi-fi                       { &:before { content: "\e93d"; } }
.icon-line-graph                  { &:before { content: "\e93e"; } }
.icon-pie-chart                   { &:before { content: "\e93f"; } }
.icon-bar-graph                   { &:before { content: "\e940"; } }
.icon-scatter-graph               { &:before { content: "\e941"; } }
.icon-padlock-locked              { &:before { content: "\e942"; } }
.icon-padlock-unlocked            { &:before { content: "\e943"; } }
.icon-tick                        { &:before { content: "\e944"; } }
.icon-cross                       { &:before { content: "\e945"; } }
.icon-minus                       { &:before { content: "\e946"; } }
.icon-plus                        { &:before { content: "\e947"; } }
.icon-info                        { &:before { content: "\e948"; } }
.icon-question                    { &:before { content: "\e949"; } }
.icon-warning                     { &:before { content: "\e94a"; } }
.icon-list                        { &:before { content: "\e94b"; } }
.icon-add-to-list                 { &:before { content: "\e94c"; } }
.icon-menu                        { &:before { content: "\e94d"; } }
.icon-grid                        { &:before { content: "\e94e"; } }
.icon-portrait                    { &:before { content: "\e94f"; } }
.icon-screens                     { &:before { content: "\e950"; } }
.icon-landscape                   { &:before { content: "\e951"; } }
.icon-images                      { &:before { content: "\e952"; } }
.icon-media                       { &:before { content: "\e953"; } }
.icon-folder                      { &:before { content: "\e954"; } }
.icon-documents                   { &:before { content: "\e955"; } }
.icon-delete                      { &:before { content: "\e956"; } }
.icon-save                        { &:before { content: "\e957"; } }
.icon-download                    { &:before { content: "\e958"; } }
.icon-upload                      { &:before { content: "\e959"; } }
.icon-expand                      { &:before { content: "\e95a"; } }
.icon-shrink                      { &:before { content: "\e95b"; } }
.icon-volume                      { &:before { content: "\e95c"; } }
.icon-sound                       { &:before { content: "\e95d"; } }
.icon-sound-mute                  { &:before { content: "\e95e"; } }
.icon-controls-down               { &:before { content: "\e95f"; } }
.icon-controls-left               { &:before { content: "\e960"; .dir-rtl & { content: "\e961"; } } }
.icon-controls-right              { &:before { content: "\e961"; .dir-rtl & { content: "\e960"; } } }
.icon-controls-up                 { &:before { content: "\e962"; } }
.icon-triangle-down               { &:before { content: "\e963"; } }
.icon-triangle-left               { &:before { content: "\e964"; .dir-rtl & { content: "\e965"; } } }
.icon-triangle-right              { &:before { content: "\e965"; .dir-rtl & { content: "\e964"; } } }
.icon-triangle-up                 { &:before { content: "\e966"; } }
.icon-dot                         { &:before { content: "\e967"; } }
.icon-ellipsis                    { &:before { content: "\e968"; } }
.icon-up-down                     { &:before { content: "\e969"; } }
.icon-controls-small-down         { &:before { content: "\e96a"; } }
.icon-controls-small-left         { &:before { content: "\e96b"; .dir-rtl & { content: "\e96c"; } } }
.icon-controls-small-right        { &:before { content: "\e96c"; .dir-rtl & { content: "\e96b"; } } }
.icon-controls-small-up           { &:before { content: "\e96d"; } }
.icon-radio-checked               { &:before { content: "\e96e"; } }
.icon-radio-unchecked             { &:before { content: "\e96f"; } }
.icon-language-1                  { &:before { content: "\e970"; } }
.icon-accessibility               { &:before { content: "\e971"; } }
.icon-globe                       { &:before { content: "\e972"; } }
.icon-language-2                  { &:before { content: "\e973"; } }
.icon-checkbox-checked            { &:before { content: "\e974"; } }
.icon-checkbox-unchecked          { &:before { content: "\e975"; } }

.icon-accessibility-2             { &:before { content: "\e976"; } }
.icon-alert                       { &:before { content: "\e977"; } }
.icon-arrow-down-2                { &:before { content: "\e978"; } }
.icon-arrow-left-2                { &:before { content: "\e979"; .dir-rtl & { content: "\e97a"; } } }
.icon-arrow-right-2               { &:before { content: "\e97a"; .dir-rtl & { content: "\e979"; } } }
.icon-arrow-up-2                  { &:before { content: "\e97b"; } }
.icon-arrow-down-3                { &:before { content: "\e97c"; } }
.icon-arrow-left-3                { &:before { content: "\e97d"; .dir-rtl & { content: "\e97e"; } } }
.icon-arrow-right-3               { &:before { content: "\e97e"; .dir-rtl & { content: "\e97d"; } } }
.icon-arrow-up-3                  { &:before { content: "\e97f"; } }
.icon-attachment                  { &:before { content: "\e980"; } }
.icon-block                       { &:before { content: "\e981"; } }
.icon-camera-off                  { &:before { content: "\e982"; } }
.icon-camera-on                   { &:before { content: "\e983"; } }
.icon-cloud                       { &:before { content: "\e984"; } }
.icon-compass                     { &:before { content: "\e985"; } }
.icon-copy-file                   { &:before { content: "\e986"; } }
.icon-copyright                   { &:before { content: "\e987"; } }
.icon-crop                        { &:before { content: "\e988"; } }
.icon-devices                     { &:before { content: "\e989"; } }
.icon-down-up                     { &:before { content: "\e98a"; } }
.icon-ellipsis-vertical           { &:before { content: "\e98b"; } }
.icon-exclamation                 { &:before { content: "\e98c"; } }
.icon-expand_1                    { &:before { content: "\e98d"; } }
.icon-extension                   { &:before { content: "\e98e"; } }
.icon-favourite                   { &:before { content: "\e98f"; } }
.icon-google-translate            { &:before { content: "\e990"; } }
.icon-hand                        { &:before { content: "\e991"; } }
.icon-lightning                   { &:before { content: "\e992"; } }
.icon-list-2                      { &:before { content: "\e993"; } }
.icon-microphone-off              { &:before { content: "\e994"; } }
.icon-move                        { &:before { content: "\e995"; } }
.icon-mute                        { &:before { content: "\e996"; } }
.icon-ordered-list                { &:before { content: "\e997"; } }
.icon-question-circle             { &:before { content: "\e998"; } }
.icon-quote-close                 { &:before { content: "\e999"; } }
.icon-quote-open                  { &:before { content: "\e99a"; } }
.icon-refresh                     { &:before { content: "\e99b"; } }
.icon-send                        { &:before { content: "\e99c"; } }
.icon-sound-down                  { &:before { content: "\e99d"; } }
.icon-sync                        { &:before { content: "\e99e"; } }
.icon-tablet                      { &:before { content: "\e99f"; } }
.icon-thumb-down                  { &:before { content: "\e9a0"; } }
.icon-thumb-up                    { &:before { content: "\e9a1"; } }
.icon-timer-empty                 { &:before { content: "\e9a2"; } }
.icon-timer-full                  { &:before { content: "\e9a3"; } }
.icon-video-captions-off-2        { &:before { content: "\e9a4"; } }
.icon-video-captions-off          { &:before { content: "\e9a5"; } }
.icon-video-captions              { &:before { content: "\e9a6"; } }
.icon-video-exit-fullscreen       { &:before { content: "\e9a7"; } }
.icon-video-fast-forward          { &:before { content: "\e9a8"; } }
.icon-video-fullscreen            { &:before { content: "\e9a9"; } }
.icon-video-loop                  { &:before { content: "\e9aa"; } }
.icon-video-pause-circle          { &:before { content: "\e9ab"; } }
.icon-video-pause                 { &:before { content: "\e9ac"; } }
.icon-video-play-circle           { &:before { content: "\e9ad"; } }
.icon-video-play                  { &:before { content: "\e9ae"; } }
.icon-video-replay                { &:before { content: "\e9af"; } }
.icon-video-rewind                { &:before { content: "\e9b0"; } }
.icon-video-skip-back             { &:before { content: "\e9b1"; } }
.icon-video-skip-forward          { &:before { content: "\e9b2"; } }
.icon-video-stop                  { &:before { content: "\e9b3"; } }

.icon-quote-close-2               { &:before { content: "\e9b4"; } }
.icon-quote-open-2                { &:before { content: "\e9b5"; } }
.icon-emotion-happy               { &:before { content: "\e9b6"; } }
.icon-emotion-not-ok              { &:before { content: "\e9b7"; } }
.icon-emotion-ok                  { &:before { content: "\e9b8"; } }
.icon-emotion-sad                 { &:before { content: "\e9b9"; } }
.icon-emotion-very-happy          { &:before { content: "\e9ba"; } }
.icon-emotion-very-unhappy        { &:before { content: "\e9bb"; } }
.icon-blogger-2                   { &:before { content: "\e9bc"; } }
.icon-blogger                     { &:before { content: "\e9bd"; } }
.icon-deviantart-2                { &:before { content: "\e9be"; } }
.icon-deviantart                  { &:before { content: "\e9bf"; } }
.icon-doc-excel                   { &:before { content: "\e9c0"; } }
.icon-doc-pdf                     { &:before { content: "\e9c1"; } }
.icon-doc-word                    { &:before { content: "\e9c2"; } }
.icon-facebook-2                  { &:before { content: "\e9c3"; } }
.icon-facebook                    { &:before { content: "\e9c4"; } }
.icon-flickr-2                    { &:before { content: "\e9c5"; } }
.icon-flickr                      { &:before { content: "\e9c6"; } }
.icon-github                      { &:before { content: "\e9c7"; } }
.icon-instagram-2                 { &:before { content: "\e9c8"; } }
.icon-instagram                   { &:before { content: "\e9c9"; } }
.icon-linkedin-2                  { &:before { content: "\e9ca"; } }
.icon-linkedin                    { &:before { content: "\e9cb"; } }
.icon-paypal                      { &:before { content: "\e9cc"; } }
.icon-pintrest-2                  { &:before { content: "\e9cd"; } }
.icon-pintrest                    { &:before { content: "\e9ce"; } }
.icon-qq-2                        { &:before { content: "\e9cf"; } }
.icon-qq                          { &:before { content: "\e9d0"; } }
.icon-reddit-2                    { &:before { content: "\e9d1"; } }
.icon-reddit                      { &:before { content: "\e9d2"; } }
.icon-rss-2                       { &:before { content: "\e9d3"; } }
.icon-rss                         { &:before { content: "\e9d4"; } }
.icon-sina-weibo-2                { &:before { content: "\e9d5"; } }
.icon-sina-weibo                  { &:before { content: "\e9d6"; } }
.icon-soundcloud-2                { &:before { content: "\e9d7"; } }
.icon-soundcloud                  { &:before { content: "\e9d8"; } }
.icon-steam-2                     { &:before { content: "\e9d9"; } }
.icon-steam                       { &:before { content: "\e9da"; } }
.icon-trello-2                    { &:before { content: "\e9db"; } }
.icon-trello                      { &:before { content: "\e9dc"; } }
.icon-tumbler-2                   { &:before { content: "\e9dd"; } }
.icon-tumblr                      { &:before { content: "\e9de"; } }
.icon-twitch                      { &:before { content: "\e9df"; } }
.icon-twitter-2                   { &:before { content: "\e9e0"; } }
.icon-twitter                     { &:before { content: "\e9e1"; } }
.icon-vimeo-2                     { &:before { content: "\e9e2"; } }
.icon-vimeo                       { &:before { content: "\e9e3"; } }
.icon-wechat-2                    { &:before { content: "\e9e4"; } }
.icon-wechat                      { &:before { content: "\e9e5"; } }
.icon-weibo-2                     { &:before { content: "\e9e6"; } }
.icon-weibo                       { &:before { content: "\e9e7"; } }
.icon-xing-2                      { &:before { content: "\e9e8"; } }
.icon-xing                        { &:before { content: "\e9e9"; } }
.icon-youku-2                     { &:before { content: "\e9ea"; } }
.icon-youku                       { &:before { content: "\e9eb"; } }
.icon-youtube-2                   { &:before { content: "\e9ec"; } }
.icon-youtube                     { &:before { content: "\e9ed"; } }
