.input-field() {
  align-items: center;
  padding: @slh-input-item-padding (@icon-size + (@slh-input-item-padding * 2)) @slh-input-item-padding @slh-input-item-padding;
  border: 2px solid @slh-admin-color-neutral-shade-20;
  background-color: @slh-admin-color-neutral-shade-00;
  border-radius: 4px;
  height: 2.5rem;
  font-size: @slh-admin-base-xs-font-size;

  &:focus-visible {
    border: 2px solid @btn-color;
    outline: none;
  }

  .icon {
    &:before {
      font-size: @slh-base-xs-fontsize;
    }
  }
}