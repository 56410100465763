@collapsible-boxes-item-padding: 1rem;
@collapsible-boxes-attribution-font: 0.75rem;
@collapsible-boxes-attribution-color: #39455D;

.OnSmallScreens(@rules) { @media all and (max-width: @device-width-small){@rules(); }};
.onDesktop(@rules) { @media screen and (min-width:@device-width-medium){ @rules(); } };

.row-items(@item-width) {
  .collapsibleBoxes-item {
    width: @item-width;
    padding-right: @collapsible-boxes-item-padding;
  }
}
.image-size(@img-size, @txt-size) {
  .collapsibleBoxes-item__image-container{
    flex: @img-size;
    padding: @collapsible-boxes-item-padding;
    .OnSmallScreens({
      padding: 0;
    })
  }
  .collapsibleBoxes-item__body {
    flex: @txt-size;
  }
}
.allImageSizes {
  &.image-size-30 {
    .image-size(30%, 70%);
  }
  &.image-size-50 {
    .image-size(50%, 50%);
  }
  &.image-size-70 {
    .image-size(70%, 30%);
  }
}