.matching {
  // Class to show the users selection
  // --------------------------------------------------
  &__widget.show-user-answer .is-correct &-item__select-correct-icon,
  &__widget.show-user-answer .is-incorrect &-item__select-incorrect-icon {
    display: block;
  }

  // Class to show the correct answers
  // --------------------------------------------------
  &__widget.show-correct-answer .is-correct &-item__select-correct-icon,
  &__widget.show-correct-answer .is-incorrect &-item__select-correct-icon {
    display: block;
  }
}

.matching-item {
  &__select-icon {
    display: none;
  }

  // Matching item icons
  // --------------------------------------------------
  &__select-correct-icon .icon {
    .icon-tick;
  }

  &__select-incorrect-icon .icon {
    .icon-cross;
  }
}
