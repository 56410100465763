// Icons
// --------------------------------------------------
@font-face {
  font-family: "slh";
  src: url('fonts/slh_iconfont_default.woff2') format('woff2'),
       url('fonts/slh_iconfont_default.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  &:before {
    display: block;
    font-family: "slh";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    font-size: @slh-base-xs-fontsize;
    text-align: center;
    text-decoration: none;

    // Better font rendering
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

// Icons as classes
// --------------------------------------------------


// SLH updated --------------------------------------
.icon-controls-down               { &:before { content: "\e937"; } }
.icon-controls-left               { &:before { content: "\e938"; .dir-rtl & { content: "\e939"; } } }
.icon-controls-right              { &:before { content: "\e939"; .dir-rtl & { content: "\e938"; } } }
.icon-controls-up                 { &:before { content: "\e93a"; } }
.icon-cross                       { &:before { content: "\e90b"; } }
.icon-thumb-down                  { &:before { content: "\e9c5"; } }
.icon-thumb-up                    { &:before { content: "\e9c6"; } }
.icon-arrow-down                  { &:before { content: "\e934"; } }
.icon-arrow-left                  { &:before { content: "\e935"; .dir-rtl & { content: "\e93b"; } } }
.icon-arrow-right                 { &:before { content: "\e93b"; .dir-rtl & { content: "\e935"; } } }
.icon-arrow-up                    { &:before { content: "\e93c"; } }
.icon-time                        { &:before { content: "\e927"; } }
.icon-menu                        { &:before { content: "\e992"; } }
.icon-ellipsis                    { &:before { content: "\e98d"; } }
.icon-ellipsis-vertical           { &:before { content: "\e98e"; } }
.icon-video-play                  { &:before { content: "\e919"; } }
.icon-book                        { &:before { content: "\e905"; } }
.icon-tag                         { &:before { content: "\e91d"; } }
.icon-persons                     { &:before { content: "\e92d"; } }
.icon-pencil                      { &:before { content: "\e906"; } }
.icon-controls-small-down         { &:before { content: "\e937"; } }
.icon-controls-small-left         { &:before { content: "\e938"; .dir-rtl & { content: "\e939"; } } }
.icon-controls-small-right        { &:before { content: "\e939"; .dir-rtl & { content: "\e938"; } } }
.icon-controls-small-up           { &:before { content: "\e93a"; } }
.icon-list                        { &:before { content: "\e992"; } }
.icon-tick                        { &:before { content: "\e924"; } }
.icon-video-rewind                { &:before { content: "\e970"; } }
.icon-forward-arrow               { &:before { content: "\e970"; .dir-rtl & { content: "\e9af"; } } }
.icon-backwards-arrow             { &:before { content: "\e9af"; .dir-rtl & { content: "\e970"; } } }
.icon-home                        { &:before { content: "\e93d"; } }
.icon-comment                     { &:before { content: "\e954"; } }
.icon-delete                      { &:before { content: "\e95d"; } }
.icon-text-document               { &:before { content: "\e95f"; } }
.icon-download                    { &:before { content: "\e964"; } }
.icon-search                      { &:before { content: "\e9b2"; } }
.icon-upload                      { &:before { content: "\e9cc"; } }
.icon-video-captions              { &:before { content: "\e902"; } }
.icon-video-exit-fullscreen       { &:before { content: "\e969"; } }
.icon-video-fullscreen            { &:before { content: "\e971"; } }
.icon-video-skip-back             { &:before { content: "\e901"; } }
.icon-video-skip-forward          { &:before { content: "\e90c"; } }
.icon-video-pause                 { &:before { content: "\e918"; } }
.icon-video-replay                { &:before { content: "\e91a"; } }
.icon-volume                      { &:before { content: "\e91e"; } }
.icon-sound                       { &:before { content: "\e91e"; } }
.icon-sound-mute                  { &:before { content: "\e920"; } }
.icon-star                        { &:before { content: "\e921"; } }
.icon-star-hollow                 { &:before { content: "\e922"; } }
.icon-video-stop                  { &:before { content: "\e923"; } }
.icon-alert                       { &:before { content: "\e931"; } }
.icon-attachment                  { &:before { content: "\e93e"; } }
.icon-bookmark                    { &:before { content: "\e941"; } }
.icon-emotion-happy               { &:before { content: "\e9be"; } }
.icon-emotion-very-happy          { &:before { content: "\e9bd"; } }
.icon-emotion-sad                 { &:before { content: "\e9bc"; } }
.icon-youtube                     { &:before { content: "\e9ce"; } }
.icon-plus                        { &:before { content: "\e99a"; } }
.icon-minus                       { &:before { content: "\E9D0"; } }
.icon-circle-plus                 { &:before { content: "\e999"; } }
.icon-circle-minus                { &:before { content: "\e97a"; } }
.icon-radio-checked               { &:before { content: "\e9ab"; } }
.icon-radio-unchecked             { &:before { content: "\e9aa"; } }
.icon-triangle-down               { &:before { content: "\E937"; } }
.icon-triangle-left               { &:before { content: "\e938"; .dir-rtl & { content: "\e939"; } } }
.icon-triangle-right              { &:before { content: "\e939"; .dir-rtl & { content: "\e938"; } } }
.icon-triangle-up                 { &:before { content: "\e93a"; } }
.icon-cog                         { &:before { content: "\e99C"; } }
.icon-checkbox-checked            { &:before { content: "\e947"; } }
.icon-checkbox-unchecked          { &:before { content: "\e94a"; } }
.icon-padlock-locked              { &:before { content: "\e9B5"; } }
.icon-circle-checkbox-checked     { &:before { content: "\e997"; } }
.icon-circle-checkbox-unchecked   { &:before { content: "\e9aa"; } }
.icon-info                        { &:before { content: "\e97b"; } }
.icon-question                    { &:before { content: "\e974"; } }
.icon-light-bulb                  { &:before { content: "\e9c7"; } }
.icon-globe                       { &:before { content: "\e98a"; } }
.icon-language-1                  { &:before { content: "\e9d1"; } }
.icon-language-2                  { &:before { content: "\e9d2"; } }
.icon-page-level-progress         { &:before { content: "\e9d3"; } }
.icon_pageLevelProgress           { &:before { content: "\e9d3"; } }
.icon_glossary                    { &:before { content: "\e905"; } } //used for sticky menu button, same as icon-book
.icon_search                      { &:before { content: "\e9b2"; } } //used for sticky menu button, same as icon-search
.icon_resources                   { &:before { content: "\e93e"; } } //used for sticky menu button, same as icon-attachment
.icon_adaptNotes                  { &:before { content: "\e967"; } } //used for sticky menu button


// Backwards compatibility
.ico_info                        { &:before { content: "\e97b"; } }

// --------------------------------------------------

// TODO: Change vanilla icons to suitable SLH icons if there is a need

// .icon-arrow-with-circle-down      { &:before { content: "\e900"; } }
// .icon-arrow-with-circle-left      { &:before { content: "\e901"; .dir-rtl & { content: "\e902"; } } }
// .icon-arrow-with-circle-right     { &:before { content: "\e902"; .dir-rtl & { content: "\e901"; } } }
// .icon-arrow-with-circle-up        { &:before { content: "\e903"; } }
// .icon-chevron-with-circle-down    { &:before { content: "\e904"; } }
// .icon-chevron-with-circle-left    { &:before { content: "\e905"; .dir-rtl & { content: "\e906"; } } }
// .icon-chevron-with-circle-right   { &:before { content: "\e906"; .dir-rtl & { content: "\e905"; } } }
// .icon-chevron-with-circle-up      { &:before { content: "\e907"; } }
// .icon-info-with-circle            { &:before { content: "\e908"; } }
// .icon-location                    { &:before { content: "\e909"; } }
// .icon-medal                       { &:before { content: "\e90a"; } }
// .icon-price-ribbon                { &:before { content: "\e90b"; } }
// .icon-shield                      { &:before { content: "\e90c"; } }
// .icon-stopwatch                   { &:before { content: "\e90d"; } }
// .icon-text-document-inverted      { &:before { content: "\e90e"; } }
// .icon-trophy                      { &:before { content: "\e910"; } }
// .icon-message                     { &:before { content: "\e916"; } }
// .icon-mouse                       { &:before { content: "\e917"; } }
// .icon-mobile                      { &:before { content: "\e918"; } }
// .icon-desktop                     { &:before { content: "\e919"; } }
// .icon-person                      { &:before { content: "\e91d"; } }
// .icon-profile                     { &:before { content: "\e91f"; } }
// .icon-pin                         { &:before { content: "\e920"; } }
// .icon-map                         { &:before { content: "\e921"; } }
// .icon-point                       { &:before { content: "\e922"; } }
// .icon-comments                    { &:before { content: "\e925"; } }
// .icon-popup                       { &:before { content: "\e928"; } }
// .icon-printer                     { &:before { content: "\e92a"; } }
// .icon-link                        { &:before { content: "\e92b"; } }
// .icon-flag                        { &:before { content: "\e92c"; } }

// .icon-tools                       { &:before { content: "\e92e"; } }
// .icon-camera                      { &:before { content: "\e930"; } }
// .icon-feedback                    { &:before { content: "\e931"; } }
// .icon-article                     { &:before { content: "\e933"; } }
// .icon-eye                         { &:before { content: "\e934"; } }
// .icon-microphone                  { &:before { content: "\e936"; } }
// .icon-calendar                    { &:before { content: "\e937"; } }
// .icon-timer                       { &:before { content: "\e938"; } }
// .icon-drag                        { &:before { content: "\e939"; } }
// .icon-light-bulb                  { &:before { content: "\e93a"; } }
// .icon-checklist                   { &:before { content: "\e93b"; } }
// .icon-ticket                      { &:before { content: "\e93c"; } }
// .icon-wi-fi                       { &:before { content: "\e93d"; } }
// .icon-line-graph                  { &:before { content: "\e93e"; } }
// .icon-pie-chart                   { &:before { content: "\e93f"; } }
// .icon-bar-graph                   { &:before { content: "\e940"; } }
// .icon-scatter-graph               { &:before { content: "\e941"; } }
// .icon-padlock-locked              { &:before { content: "\e942"; } }
// .icon-padlock-unlocked            { &:before { content: "\e943"; } }

// 

// 

// .icon-question                    { &:before { content: "\e949"; } }
// .icon-warning                     { &:before { content: "\e94a"; } }
// .icon-add-to-list                 { &:before { content: "\e94c"; } }
// .icon-grid                        { &:before { content: "\e94e"; } }
// .icon-portrait                    { &:before { content: "\e94f"; } }
// .icon-screens                     { &:before { content: "\e950"; } }
// .icon-landscape                   { &:before { content: "\e951"; } }
// .icon-images                      { &:before { content: "\e952"; } }
// .icon-media                       { &:before { content: "\e953"; } }
// .icon-folder                      { &:before { content: "\e954"; } }
// .icon-documents                   { &:before { content: "\e955"; } }
// .icon-save                        { &:before { content: "\e957"; } }
// .icon-expand                      { &:before { content: "\e95a"; } }
// .icon-shrink                      { &:before { content: "\e95b"; } }




// .icon-dot                         { &:before { content: "\e967"; } }
// .icon-up-down                     { &:before { content: "\e969"; } }

// .icon-language-1                  { &:before { content: "\e970"; } }
// .icon-accessibility               { &:before { content: "\e971"; } }
// .icon-globe                       { &:before { content: "\e972"; } }
// .icon-language-2                  { &:before { content: "\e973"; } }

// .icon-accessibility-2             { &:before { content: "\e976"; } }
// .icon-arrow-down-2                { &:before { content: "\e978"; } }
// .icon-arrow-left-2                { &:before { content: "\e979"; .dir-rtl & { content: "\e97a"; } } }
// .icon-arrow-right-2               { &:before { content: "\e97a"; .dir-rtl & { content: "\e979"; } } }
// .icon-arrow-up-2                  { &:before { content: "\e97b"; } }
// .icon-arrow-down-3                { &:before { content: "\e97c"; } }
// .icon-arrow-left-3                { &:before { content: "\e97d"; .dir-rtl & { content: "\e97e"; } } }
// .icon-arrow-right-3               { &:before { content: "\e97e"; .dir-rtl & { content: "\e97d"; } } }
// .icon-arrow-up-3                  { &:before { content: "\e97f"; } }
// .icon-block                       { &:before { content: "\e981"; } }
// .icon-camera-off                  { &:before { content: "\e982"; } }
// .icon-camera-on                   { &:before { content: "\e983"; } }
// .icon-cloud                       { &:before { content: "\e984"; } }
// .icon-compass                     { &:before { content: "\e985"; } }
// .icon-copy-file                   { &:before { content: "\e986"; } }
// .icon-copyright                   { &:before { content: "\e987"; } }
// .icon-crop                        { &:before { content: "\e988"; } }
// .icon-devices                     { &:before { content: "\e989"; } }
// .icon-down-up                     { &:before { content: "\e98a"; } }
// .icon-exclamation                 { &:before { content: "\e98c"; } }
// .icon-expand_1                    { &:before { content: "\e98d"; } }
// .icon-extension                   { &:before { content: "\e98e"; } }
// .icon-favourite                   { &:before { content: "\e98f"; } }
// .icon-google-translate            { &:before { content: "\e990"; } }
// .icon-hand                        { &:before { content: "\e991"; } }
// .icon-lightning                   { &:before { content: "\e992"; } }
// .icon-list-2                      { &:before { content: "\e993"; } }
// .icon-microphone-off              { &:before { content: "\e994"; } }
// .icon-move                        { &:before { content: "\e995"; } }
// .icon-mute                        { &:before { content: "\e996"; } }
// .icon-ordered-list                { &:before { content: "\e997"; } }
// .icon-question-circle             { &:before { content: "\e998"; } }
// .icon-quote-close                 { &:before { content: "\e999"; } }
// .icon-quote-open                  { &:before { content: "\e99a"; } }
// .icon-refresh                     { &:before { content: "\e99b"; } }
// .icon-send                        { &:before { content: "\e99c"; } }
// .icon-sound-down                  { &:before { content: "\e99d"; } }
// .icon-sync                        { &:before { content: "\e99e"; } }
// .icon-tablet                      { &:before { content: "\e99f"; } }
// .icon-timer-empty                 { &:before { content: "\e9a2"; } }
// .icon-timer-full                  { &:before { content: "\e9a3"; } }
// .icon-video-captions-off-2        { &:before { content: "\e9a4"; } }
// .icon-video-captions-off          { &:before { content: "\e9a5"; } }
// .icon-video-fast-forward          { &:before { content: "\e9a8"; } }
// .icon-video-loop                  { &:before { content: "\e9aa"; } }
// .icon-video-pause-circle          { &:before { content: "\e9ab"; } }
// .icon-video-play-circle           { &:before { content: "\e9ad"; } }
// .icon-quote-close-2               { &:before { content: "\e9b4"; } }
// .icon-quote-open-2                { &:before { content: "\e9b5"; } }
// .icon-emotion-not-ok              { &:before { content: "\e9b7"; } }
// .icon-emotion-ok                  { &:before { content: "\e9b8"; } }
// .icon-emotion-very-unhappy        { &:before { content: "\e9bb"; } }
// .icon-blogger-2                   { &:before { content: "\e9bc"; } }
// .icon-blogger                     { &:before { content: "\e9bd"; } }
// .icon-deviantart-2                { &:before { content: "\e9be"; } }
// .icon-deviantart                  { &:before { content: "\e9bf"; } }
// .icon-doc-excel                   { &:before { content: "\e968"; } }
// .icon-doc-word                    { &:before { content: "\e9cf"; } }
// .icon-doc-pdf                     { &:before { content: "\e998"; } }
// .icon-facebook-2                  { &:before { content: "\e9c3"; } }
// .icon-facebook                    { &:before { content: "\e9c4"; } }
// .icon-flickr-2                    { &:before { content: "\e9c5"; } }
// .icon-flickr                      { &:before { content: "\e9c6"; } }
// .icon-github                      { &:before { content: "\e9c7"; } }
// .icon-instagram-2                 { &:before { content: "\e9c8"; } }
// .icon-instagram                   { &:before { content: "\e9c9"; } }
// .icon-linkedin-2                  { &:before { content: "\e9ca"; } }
// .icon-linkedin                    { &:before { content: "\e9cb"; } }
// .icon-paypal                      { &:before { content: "\e9cc"; } }
// .icon-pintrest-2                  { &:before { content: "\e9cd"; } }
// .icon-pintrest                    { &:before { content: "\e9ce"; } }
// .icon-qq-2                        { &:before { content: "\e9cf"; } }
// .icon-qq                          { &:before { content: "\e9d0"; } }
// .icon-reddit-2                    { &:before { content: "\e9d1"; } }
// .icon-reddit                      { &:before { content: "\e9d2"; } }
// .icon-rss-2                       { &:before { content: "\e9d3"; } }
// .icon-rss                         { &:before { content: "\e9d4"; } }
// .icon-sina-weibo-2                { &:before { content: "\e9d5"; } }
// .icon-sina-weibo                  { &:before { content: "\e9d6"; } }
// .icon-soundcloud-2                { &:before { content: "\e9d7"; } }
// .icon-soundcloud                  { &:before { content: "\e9d8"; } }
// .icon-steam-2                     { &:before { content: "\e9d9"; } }
// .icon-steam                       { &:before { content: "\e9da"; } }
// .icon-trello-2                    { &:before { content: "\e9db"; } }
// .icon-trello                      { &:before { content: "\e9dc"; } }
// .icon-tumbler-2                   { &:before { content: "\e9dd"; } }
// .icon-tumblr                      { &:before { content: "\e9de"; } }
// .icon-twitch                      { &:before { content: "\e9df"; } }
// .icon-twitter-2                   { &:before { content: "\e9e0"; } }
// .icon-twitter                     { &:before { content: "\e9e1"; } }
// .icon-vimeo-2                     { &:before { content: "\e9e2"; } }
// .icon-vimeo                       { &:before { content: "\e9e3"; } }
// .icon-wechat-2                    { &:before { content: "\e9e4"; } }
// .icon-wechat                      { &:before { content: "\e9e5"; } }
// .icon-weibo-2                     { &:before { content: "\e9e6"; } }
// .icon-weibo                       { &:before { content: "\e9e7"; } }
// .icon-xing-2                      { &:before { content: "\e9e8"; } }
// .icon-xing                        { &:before { content: "\e9e9"; } }
// .icon-youku-2                     { &:before { content: "\e9ea"; } }
// .icon-youku                       { &:before { content: "\e9eb"; } }
// .icon-youtube-2                   { &:before { content: "\e9ec"; } }
