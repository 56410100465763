.media {
  &__header {
    margin-bottom: 1rem;
  }
  .video-js {
    .vjs-seek-to-live-control {
      display: none;
    }
    &.audio-js {
      .vjs-big-play-button {
        display: none;
        &.playbackRate-05 {
          display: none;
        }
        &.playbackRate-10 {
          display: none;
        }
        &.playbackRate-15 {
          display: none;
        }
        &.playbackRate-20 {
          display: none;
        }
      }
      .vjs-control-bar {
        display: flex;
      }
    }
  }

  &__inline-transcript-body-container {
    display: none;
  }

  &__transcript-button-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap-reverse;
  }

  &__external-transcript-button,
  &__inline-transcript-button {
    max-width: 100%;
    display: block;
    margin: 0.5rem;
    word-wrap: break-word;
  }
}
