.notify {
  z-index: 510; //needs to be above 500 because of iosScrollFix
  .slh-notify;

  &__popup {
    background-color: @notify;
    color: @notify-inverted;
    .transition(margin-top @duration, top @duration;);
  }

  &__popup-inner {
    position: relative;
    .l-container-padding(@notify-padding-ver, @notify-padding-hoz);
    box-shadow: 0px 0px 10px 2px @slh-admin-color-neutral-shade-80;
    height: 100%;
  }

  &__content {
    overflow-y: auto;
    height: 100%;
  }

  &__icon {
    display: none;
  }

  &__title {
    margin-bottom: @notify-title-margin;
    .notify-title;

    // Converted media query to use min width to sync responsive trigger with js
    // https://github.com/adaptlearning/adapt_framework/issues/2865
    padding-right: unit(@icon-size + @btn-margin + @btn-padding, rem);

    .dir-rtl & {
      padding-right: inherit;
      padding-left: unit(@icon-size + @btn-margin + @btn-padding, rem);
    }

    @media (min-width: @device-width-medium) {
      padding-right: inherit;

      .dir-rtl & {
        padding-left: inherit;
      }
    }
  }

  &__body {
    margin-bottom: @notify-body-margin;
  }

  &__instruction {
    margin-bottom: @notify-instruction-margin;
    .instruction-text;
  }

  &__body a,
  &__instruction a {
    .link-text;
    color: @notify-link;

    .no-touch &:hover {
      color: @notify-link-hover;
      .transition(color @duration ease-in);
    }
  }

  &__btn-container {
    margin-top: @btn-margin;
  }

  &__btn {
    .no-touch &:not(.is-disabled):hover {
      .transition(background-color @duration ease-in, color @duration ease-in;);
    }
  }

  &__btn-icon {
    margin: @btn-margin / 2;
    padding: @btn-padding / 2;
    background-color: @notify-icon;
    color: @notify-icon-inverted;
    border-radius: 50%;

    .no-touch &:not(.is-disabled):not(.is-locked):hover {
      background-color: @notify-icon-hover;
      color: @notify-icon-inverted-hover;
      .transition(background-color @duration ease-in, color @duration ease-in;);
    }
  }

  &__close-btn {
    position: absolute;
    top: 0;
    right: 0;

    .dir-rtl & {
      right: inherit;
      left: 0;
    }
  }

  // Notify prompt type icon
  // --------------------------------------------------
  // &__icon {
  //   position: absolute;
  //   top: 0;
  //   right: 0;

  //   .dir-rtl & {
  //     right: inherit;
  //     left: 0;
  //   }
  // }
}
